import React from 'react';
import Aux from "../../Aux/Aux";
import styled from "styled-components"
import { Timeline, Text } from '@mantine/core';
import {
    IconTruck,
    IconBuildingStore,
    IconWash,
    IconHanger,
    IconWashTumbleDry,
    IconSock,
} from '@tabler/icons-react';

const TrackingTable = (props) => {
    const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        },
        atLaundromatDetails = props.details[ props.details.length - 2 ],
        laundromatDate = atLaundromatDetails?.statusUpdatedAt,
        atLaundromatDate = new Date( laundromatDate );

    let washingDate = new Date( atLaundromatDate ),
        dryingDate = new Date( atLaundromatDate ),
        foldingDate = new Date( atLaundromatDate );

    if( laundromatDate && atLaundromatDate?.toString() !== 'Invalid Date') {
        washingDate.setHours( atLaundromatDate.getHours() + 2 );
        dryingDate.setHours( atLaundromatDate.getHours() + 4 );
        foldingDate.setHours( atLaundromatDate.getHours() + 6 );
    }

    return (
        <Aux>
            <Timeline active={props.active - 1} bulletSize={36} lineWidth={3}>
                <Timeline.Item bullet={<IconTruck size={24} />} title="Picked Up">
                    <Text color="dimmed" size="md">
                        {props.details.length >= 1 &&
                            <Aux>
                                {(new Date(props.details[0].statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')}
                            </Aux>
                        }
                        {props.details.length < 1 && <HiddenText>UNUSED</HiddenText> }
                    </Text>
                </Timeline.Item>
                <Timeline.Item bullet={<IconBuildingStore size={24} />} title="Delivered to Laundromat">
                    <Text color="dimmed" size="md">
                        {props.details.length >= 2 &&
                            <Aux>
                                {(new Date(props.details[1].statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')}
                            </Aux>
                        }
                        {props.details.length < 2 && <HiddenText>UNUSED</HiddenText> }
                    </Text>
                </Timeline.Item>
                <Timeline.Item bullet={<IconWash size={24} />} title="Washing">
                        <Text color="dimmed" size="md">
                            {props.details.length >= 2 && 3 <= props.active && laundromatDate &&
                                <Aux>
                                    {washingDate.toLocaleString('en-US', options).replace('at', '')}
                                </Aux>
                            }
                            {!(props.details.length >= 2 && 3 <= props.active) && <HiddenText>UNUSED</HiddenText> }
                        </Text>
                </Timeline.Item>
                <Timeline.Item bullet={<IconWashTumbleDry size={24} />} title="Drying">
                        <Text color="dimmed" size="md">
                            {props.details.length >= 2 && 4 <= props.active && laundromatDate &&
                                <Aux>
                                    {dryingDate.toLocaleString('en-US', options).replace('at', '')}
                                </Aux>
                            }
                            {!(props.details.length >= 2 && 4 <= props.active) && <HiddenText>UNUSED</HiddenText> }
                        </Text>
                </Timeline.Item>
                <Timeline.Item bullet={<IconSock size={24} />} title="Folding">
                        <Text color="dimmed" size="md">
                            {props.details.length >= 2 && 5 <= props.active && laundromatDate &&
                                <Aux>
                                    {foldingDate.toLocaleString('en-US', options).replace('at', '')}
                                </Aux>
                            }
                            {!(props.details.length >= 2 && 5 <= props.active) && <HiddenText>UNUSED</HiddenText> }
                        </Text>
                </Timeline.Item>
                <Timeline.Item bullet={<IconTruck size={24} />} title="In Route back to you">
                        <Text color="dimmed" size="md">
                            {props.details.length >= 3 &&
                                <Aux>
                                    {(new Date(props.details[2].statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')}
                                </Aux>
                            }
                            {props.details.length < 3 && <HiddenText>UNUSED</HiddenText> }
                        </Text>
                </Timeline.Item>
                <Timeline.Item bullet={<IconHanger size={24} />} title="Delivered">
                    <Text color="dimmed" size="md">
                        {props.details.length >= 4 &&
                            <Aux>
                                {(new Date(props.details[3].statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')}
                            </Aux>
                        }
                        {props.details.length < 4 && <HiddenText>UNUSED</HiddenText> }
                    </Text>
                </Timeline.Item>
            </Timeline>
        </Aux>
    );
};

export default TrackingTable;

const HiddenText = styled.span`
    opacity: 0;
`;

