import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import SubdomainRouter from "./routes/SubdomainRouter/v1/SubdomainRouter";
import {checkAuthentication} from "./store/reducers/auth";
import Aux from "./components/Aux/Aux";
import Spinner from "./components/Spinner/v1/Spinner";
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import {AppShell, MantineProvider} from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import Header from "./components/Headers/Header";
import "./app.css"

const App = () => {
    const dispatch = useDispatch(),
        [loading, setLoading] = useState(false);

    useEffect(() => {
        if( localStorage.getItem( "isAuthenticated") === "true" ) {
            setLoading( true );

            const validateAuth = async () => {
                await dispatch( checkAuthentication() );
                setLoading( false );
            }

            validateAuth().catch(err => { setLoading( false )});
        }
    }, [dispatch]);

    return (
        <Aux>
            <MantineProvider
                theme={{
                    fontFamily: 'Manrope, sans-serif',
                }}
            >
                <Notifications />
                { loading && <Spinner /> }
                <AppShell
                    header={{ height: 60 }}
                    navbar={{ width: 300, breakpoint: 'sm' }}
                    padding="md"
                >
                    <AppShell.Header className="custom-header">
                        <Header isLoading={setLoading} />
                    </AppShell.Header>
                    <AppShell.Main>
                        <SubdomainRouter pageLoading={loading} setLoading={setLoading} />
                    </AppShell.Main>
                </AppShell>
            </MantineProvider>
        </Aux>
    )
}

export default App;
