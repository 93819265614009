import React from "react";
import {
  Container,
  Text,
} from "@mantine/core";
import classes from "./Pricing.module.css"
import DefaultFooter from "../../components/Footers/DefaultFooter";
import PricingCard from "../../components/Reused/PricingCard";

const PricingPage = () => {
    return (
        <>
            <div className={classes.pricingHeroWrapper}>
                <Container size={700} className={classes.pricingHeroInner}>
                    <h1 className={classes.pricingHeroTitle}>
                        Simple.{' '}
                        <Text component="span" variant="gradient" gradient={{ from: 'blue', to: 'cyan' }} inherit>
                            Fresh.
                        </Text>{' '}
                        Weekly.
                    </h1>

                    <Text className={classes.pricingHeroDescription} c="dimmed">
                        We keep pricing simple and straightforward—no pricing by the pound! Stuff the hamper as much as you want! The cost will always stay the same, low price.
                    </Text>

                    <br/>
                    <br/>

                    <PricingCard redirect={"/get-started"}/>

                </Container>
            </div>

            <DefaultFooter />
        </>
    );
};

export default PricingPage;