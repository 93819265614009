import React, {useEffect, useRef, useState} from 'react';
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import EmployeeRoute from "../../EmployeeRouter/v1/EmployeRoute"
import CustomerRoute from "../../CustomerRouter/v1/CustomerRoute";
import AdminRoute from "../../AdminRouter/v1/AdminRoute";
import StaticRoute from "../../StaticRouter/v1/StaticRoute";
import NotFound from "../../../pages/NotFound/v1/NotFound";
import AuthRoute from "../../AuthRouter/v1/AuthRoute";
import TrackingRoute from "../../TrackingRouter/v1/TrackingRoute";
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../../../components/Spinner/v1/Spinner";

const SubdomainRouter = (props) => {
    const screenSize = () => {
        if( window.innerWidth <= 768 ) {
            return "Mobile";
        }
        else if( window.innerWidth > 768 && window.innerWidth < 1200 ) {
            return "Tablet";
        }
        else {
            return "Desktop";
        }
    }

    const pathname = window.location.pathname,
        subdomain = pathname?.split('/')?.[1]?.toLowerCase() || "",
        authenticationSubdomains = ['employee', 'customer', 'admin'],
        isAuthenticatedRoute = authenticationSubdomains.includes( subdomain ),
        isAuthenticated = useSelector( (state) => state.auth.isAuthenticated ),
        role = useSelector( (state) => state.auth.role ),
        [screenType, setScreenType] = useState( screenSize() ),
        [isShortContent, setIsShortContent] = useState( false ),
        contentRef = useRef(),
        navigate = useNavigate(),
        dispatch = useDispatch();

    const screenHeight = () => {
        if (contentRef.current) {
            let contentHeight = contentRef.current.offsetHeight;
            const viewportHeight = window.innerHeight;

            if( isShortContent ) {
                contentHeight += 110;
            }

            return (contentHeight < viewportHeight);
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setScreenType( screenSize() )
            setIsShortContent( screenHeight() )
        }

        setIsShortContent( screenHeight() )

        const resizeObserver = new ResizeObserver(() => {
            handleResize();
        });

        if (contentRef.current) {
            resizeObserver.observe(contentRef.current);
        }

        // Add window resize listener
        window.addEventListener('resize', handleResize);

        return () => {
            // Cleanup on unmount
            if (contentRef.current) {
                resizeObserver.unobserve(contentRef.current);
            }
            window.removeEventListener('resize', handleResize);
        }
    }, [dispatch, isAuthenticatedRoute, screenSize, screenHeight]);

    const handleNavigation = ( uri ) => {
        navigate( uri );
    }

    let authRoutes = (
        <Routes>
            { !isAuthenticated && isAuthenticatedRoute && <Route path="*" element={ <Navigate to={"/login"} /> } /> }

            {role?.toLowerCase() === 'employee' && isAuthenticated && <Route path="/employee/*" exact element={ <EmployeeRoute screenType={screenType} isShortContent={isShortContent} />} /> }
            {role?.toLowerCase() === 'customer' && isAuthenticated && <Route path="/customer/*" exact element={ <CustomerRoute screenType={screenType} isShortContent={isShortContent} />} /> }
            {role?.toLowerCase() === 'admin' && isAuthenticated && <Route path="/admin/*" exact element={ <AdminRoute />} /> }
        </Routes>
    )

    let nonAuthRoutes = (
        <Routes>
            {subdomain === 'tracking' && <Route path="/tracking/:code" exact element={ <TrackingRoute screenType={screenType} isShortContent={isShortContent} />} /> }
            { isAuthenticated && <Route path="/login" element={ <Navigate to={"/" + role?.toLowerCase()} /> } /> }
            <Route path="/404" exact element={ <NotFound />} />
            <Route path="/login" element={ <AuthRoute screenType={screenType} />} />
            <Route path="*" exact element={ <StaticRoute screenType={screenType} redirect={handleNavigation} isShortContent={isShortContent} setLoading={props.setLoading} />} />
        </Routes>
    )

    return (
        <div ref={contentRef}>
            {props.pageLoading && isAuthenticatedRoute && !isAuthenticated && <Spinner />}
            {!props.pageLoading && authRoutes}
            {!isAuthenticatedRoute && nonAuthRoutes}
        </div>
    );
}

export default SubdomainRouter
