import {put} from "redux-saga/effects";
import {
    clearCustomerDetails,
    setEmailVerified,
    setPhoneVerified,
    updateCustomerDetails,
    updateDetails, updateVerification
} from "../reducers/customer";
import {logout} from "../reducers/auth";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";


export function* handleCustomerDetails( action ) {
    try {
        const getResponse = yield fetch(process.env.REACT_APP_CUSTOMER_DETAILS, {
            method: 'GET',
            credentials: 'include',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
        })

        const data = yield getResponse.json();

        if (!getResponse.ok) {
            if( getResponse.status === 403 ) {
                yield put( logout() )
            }
            const errorMessage = data.message || "Something went wrong when retrieving customer details";
            throw Error(errorMessage);
        }
        yield put( updateDetails(data) );
    }
    catch (e) {
        console.log( e.toString() );
        yield put( clearCustomerDetails() );
    }
    yield action?.payload?.setLoading?.( false );
}

export function* handleContactUs( action ) {
    try {
        const getResponse = yield fetch(process.env.REACT_APP_CONTACT_US, {
            method: 'POST',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify(action.payload.body)
        })

        if (!getResponse.ok) {
            const data = yield getResponse.json();
            const errorMessage = data.message || "Something went wrong when attempting to send email verification code.";
            throw Error(errorMessage);
        }
        showNotification({
            title: "Contact us email sent succesfully",
            message: "Your email to our team was sent succesfully, please wait for one of our team members to get back to you.",
            color: "teal",
            icon: <IconCheck />,
            autoClose: 10000,
        });
        yield action.payload.ref.emailRef.current.value = "";
        yield action.payload.ref.nameRef.current.value = "";
        yield action.payload.ref.messageRef.current.value = "";
    }
    catch (e) {
        yield action.payload.setContactError( true );
        showNotification({
            title: "Error",
            message: "Something went wrong. Please try again later.",
            color: "red",
            icon: <IconX />,
            autoClose: 10000,
        });
    }
    yield action.payload.setLoading( false );
}

export function* handleSendEmailVerification( action ) {
    console.log("email verification code send start");
    try {
        const getResponse = yield fetch(process.env.REACT_APP_CUSTOMER_SEND_EMAIL_VERIFICATION, {
            method: 'POST',
            credentials: 'include',
        })

        if (!getResponse.ok) {
            if( getResponse.status === 403 ) {
                yield put( logout() )
            }
            const data = yield getResponse.json();
            const errorMessage = data.message || "Something went wrong when attempting to send email verification code.";
            throw Error(errorMessage);
        }
        showNotification({
            title: "Email verification code sent.",
            message: "Email verification code was sent to your email successfully, please check and enter the code on this page.",
            color: "teal",
            icon: <IconCheck />,
            autoClose: 10000,
        });
        yield put( updateVerification( {
            emailVerificationSent: true
        }) );
        console.log("email verification code send success");
    }
    catch (e) {
        console.log("email verification code send error");
        console.log( e.toString() );
        showNotification({
            title: "Error",
            message: e.toString(),
            color: "red",
            icon: <IconX />,
            autoClose: 10000,
        });
    }
    console.log("email verification code sent");
    yield action.payload.setLoading( false );
}

export function* handleVerifyEmail( action ) {
    console.log("email verify start");
    try {
        const getResponse = yield fetch(process.env.REACT_APP_CUSTOMER_VALIDATE_EMAIL_VERIFICATION, {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify(action.payload.body)
        })

        if (!getResponse.ok) {
            if( getResponse.status === 403 ) {
                yield put( logout() )
            }
            const data = yield getResponse.json();
            const errorMessage = data.message || "Something went wrong when trying to validate your email";
            yield action.payload.setVerificationCode("");
            throw Error(errorMessage);
        }
        console.log("email verify success");
        yield put(setEmailVerified( { isEmailVerified: true} ));
        yield action.payload.setError( "" );
        yield action.payload.setVerificationCode("");
        showNotification({
            title: "Email verified!",
            message: "Email verification successful.",
            color: "teal",
            icon: <IconCheck />,
            autoClose: 10000,
        });
    }
    catch (e) {
        console.log("email verify error");
        console.log( e.toString() );
        yield put(setEmailVerified( { isEmailVerified: false} ));
        yield action.payload.setError( e.toString() );
        yield action.payload.setVerificationCode("");
        showNotification({
            title: "Error",
            message: e.toString(),
            color: "red",
            icon: <IconX />,
            autoClose: 10000,
        });
    }
    console.log("email verify complete");
    yield action.payload.setLoading( false );
}

export function* handleSendPhoneVerification( action ) {
    console.log("phone verification code send start");
    try {
        const getResponse = yield fetch(process.env.REACT_APP_CUSTOMER_SEND_PHONE_VERIFICATION, {
            method: 'POST',
            credentials: 'include',
        })

        if (!getResponse.ok) {
            if( getResponse.status === 403 ) {
                yield put( logout() )
            }
            const data = yield getResponse.json();
            const errorMessage = data.message || "Something went wrong when attempting to send phone verification";
            throw Error(errorMessage);
        }
        showNotification({
            title: "Phone number verification code sent.",
            message: "Phone number verification code was sent successfully, please check and enter the code on this page.",
            color: "teal",
            icon: <IconCheck />,
            autoClose: 10000,
        });

        yield put( updateVerification( {
            phoneVerificationSent: true
        }) );
        console.log("phone verification code send success");
    }
    catch (e) {
        console.log("phone verification code send error");
        console.log( e.toString() );
        showNotification({
            title: "Error",
            message: e.toString(),
            color: "red",
            icon: <IconX />,
            autoClose: 10000,
        });
    }
    console.log("email verification code sent");
    yield action.payload.setLoading( false );
}

export function* handleVerifyPhone( action ) {
    console.log("phone verify start");
    try {
        const getResponse = yield fetch(process.env.REACT_APP_CUSTOMER_VALIDATE_PHONE_VERIFICATION, {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify(action.payload.body)
        })

        if (!getResponse.ok) {
            if( getResponse.status === 403 ) {
                yield put( logout() )
            }
            const data = yield getResponse.json();
            const errorMessage = data.message || "Something went wrong when trying to validate your phone number";
            yield action.payload.setVerificationCode("");
            throw Error(errorMessage);
        }
        console.log("email verify success");
        yield put(setPhoneVerified( { isPhoneNumberVerified: true} ));
        yield action.payload.setVerificationCode("");
        showNotification({
            title: "Phone number verified!",
            message: "Phone number verification successful.",
            color: "teal",
            icon: <IconCheck />,
            autoClose: 10000,
        });
    }
    catch (e) {
        console.log("phone verify error");
        console.log( e.toString() );
        yield put(setPhoneVerified( { isPhoneNumberVerified: false} ));
        yield action.payload.setError( e.toString() );
        yield action.payload.setVerificationCode("");
        showNotification({
            title: "Error",
            message: e.toString(),
            color: "red",
            icon: <IconX />,
            autoClose: 10000,
        });
    }
    console.log("email verify complete");
    yield action.payload.setLoading( false );
}

export function* handleUpdateCustomerInfo( action ) {
    try {
        const postResponse = yield fetch(process.env.REACT_APP_CUSTOMER_UPDATE_PROFILE, {
            method: 'PATCH',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify( action.payload.body )
        })

        if (!postResponse.ok) {
            const data = yield postResponse.json();
            const errorMessage = data.message || "Something went wrong when attempting to Update";
            throw Error(errorMessage);
        }

        yield put(updateCustomerDetails({
            firstname: action.payload.body.firstname,
            lastname: action.payload.body.lastname,
            addressLine1: action.payload.body.addressLine1,
            addressLine2: action.payload.body.addressLine2,
            city: action.payload.body.city,
            state: action.payload.body.state,
            zipCode: action.payload.body.zipCode,
            country: action.payload.body.country,
        }))
        yield action.payload.setSuccess( "Information Updated Successfully" );
        yield action.payload.setError( "" );
        yield action.payload.editProfile( false );
        yield action.payload.editAddress( false );
        showNotification({
            title: "Profile updated",
            message: "Your profile details were updated successfully.",
            color: "teal",
            icon: <IconCheck />,
            autoClose: 10000,
        });
    }
    catch (e) {
        const error = e.toString();
        yield action.payload.setError( error );
        yield action.payload.setSuccess( "" );
        showNotification({
            title: "Error",
            message: error,
            color: "red",
            icon: <IconX />,
            autoClose: 10000,
          });
    }
    yield action.payload.setLoading( false )
}

export function* handleChangePassword( action ) {
    try {
        const postResponse = yield fetch(process.env.REACT_APP_CUSTOMER_CHANGE_PASSWORD, {
            method: 'POST',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify( action.payload.body )
        })

        if (!postResponse.ok) {
            const data = yield postResponse.json();
            const errorMessage = data.message || "Something went wrong when attempting to Update";
            throw Error(errorMessage);
        }

        yield action.payload.setSuccess( "Information Updated Successfully" );
        yield action.payload.setError( "" );
        yield action.payload.editPassword( false );
        showNotification({
            title: "Password updated",
            message: "Your password was updated successfully.",
            color: "teal",
            icon: <IconCheck />,
            autoClose: 10000,
        });
    }
    catch (e) {
        const error = e.toString();
        yield action.payload.setError( error );
        yield action.payload.setSuccess( "" );
        showNotification({
            title: "Error",
            message: error,
            color: "red",
            icon: <IconX />,
            autoClose: 10000,
        });
    }
    yield action.payload.setLoading( false )
}

export function* handleSendPasswordResetLink( action ) {
    try {
        const postResponse = yield fetch(process.env.REACT_APP_SEND_PASSWORD_RESET_LINK, {
            method: 'POST',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify( action.payload.body )
        })

        if (!postResponse.ok) {
            const data = yield postResponse.json();
            const errorMessage = data.message || "Something went wrong when attempting to send password reset link.";
            throw Error(errorMessage);
        }
        action.payload.setSuccess(true);
        showNotification({
            title: "Password reset link sent to the email address",
            color: "teal",
            icon: <IconCheck />,
            autoClose: 10000,
        });
    }
    catch (e) {
        const error = e.toString();
        showNotification({
            title: "Error",
            message: error,
            color: "red",
            icon: <IconX />,
            autoClose: 10000,
        });
        action.payload.setSuccess(false);
    }
    yield action.payload.setLoading( false )
}

export function* handleResetPassword( action ) {
    try {
        const postResponse = yield fetch(process.env.REACT_APP_RESET_PASSWORD, {
            method: 'POST',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify( action.payload.body )
        })

        if (!postResponse.ok) {
            const data = yield postResponse.json();
            const errorMessage = data.message || "Something went wrong when attempting to send password reset link.";
            throw Error(errorMessage);
        }
        showNotification({
            title: "Password reset was successful",
            color: "teal",
            icon: <IconCheck />,
            autoClose: 10000,
        });
    }
    catch (e) {
        const error = e.toString();
        yield action.payload.setIsSuccess( false );
        showNotification({
            title: "Error",
            message: error,
            color: "red",
            icon: <IconX />,
            autoClose: 10000,
        });
    }
    yield action.payload.setLoading( false );
    yield action.payload.setIsSuccess( true );
}
