import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Title,
  Text,
  Paper,
  PinInput,
  Divider,
  Stack,
  NativeSelect,
  LoadingOverlay,
} from "@mantine/core";
import QRCodeScanner from "../../components/QRCodeScanner/v1/QRCodeScanner";
import { Anchor } from "react-bootstrap";
import { IconArrowLeft } from "@tabler/icons-react";
import { tracking } from "../../store/reducers/hamper";

const HamperStatusUpdate = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const role = useSelector((state) => state.auth.role);
  const dashboardUri = `/${role?.toLowerCase()}`;
  const dispatch = useDispatch();
  const [hamperCode, setHamperCode] = useState("");

  // Enum to text mapping
  const statuses = [
    { label: "Picked up from customer", value: "PICKED_UP_FROM_CUSTOMER" },
    { label: "Dropped off at laundromat", value: "DROPPED_OFF_AT_LAUNDROMAT" },
    { label: "Picked up from laundromat", value: "PICKED_UP_FROM_LAUNDROMAT" },
    { label: "Delivered to customer", value: "DELIVERED_TO_CUSTOMER" },
  ];

  // Initialize formData with the first value in the statuses array
  const [formData, setFormData] = useState(statuses[0]?.value || "");

  const handleVerification = async () => {
    if (hamperCode && formData) {
      const body = {
        body: {
          hamperCode: hamperCode,
          status: formData,
        },
        setHamperCode: setHamperCode,
        setLoading: setLoading,
      };
      setLoading(true);
      dispatch(tracking(body));
    }
  };

  const handleInputChange = (value) => {
    // Auto-capitalize the input value
    setHamperCode(value.toUpperCase());
  };

  const handleScanResult = (scannedCode) => {
    handleInputChange(scannedCode); // Update the input
  };

  // Trigger auto-submit when both hamperCode and status are set
  useEffect(() => {
    if (hamperCode.length === 5 && formData) {
      handleVerification();
    }
  }, [hamperCode, formData]); // Run when either hamperCode or formData changes

  return (
    <Container size="xs" my="xl">
      <Anchor
        onClick={() => navigate(dashboardUri)}
        c="blue"
        size="sm"
        mb="lg"
        underline
        style={{ display: "inline-flex", alignItems: "center" }}
      >
        <IconArrowLeft size={16} style={{ marginRight: 8 }} />
        Back to dashboard
      </Anchor>
      <Paper withBorder shadow="md" radius="md" p={30} mt={50}>
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Stack spacing="md" align="center">
          <Title order={3}>Update Hamper Status</Title>
          <Text size="sm" c="dimmed" align="center" mb="md">
            Scan or enter the hamper code to update the hamper tracking status
          </Text>
          <NativeSelect
            label="Status" 
            data={statuses.map((status) => ({
              label: status.label,
              value: status.value,
            }))}
            value={formData}
            onChange={(e) => setFormData(e.target.value)}
            required
          />
          <Divider labelPosition="center" />
          <>
            <Stack spacing="md" align="center">
              <PinInput
                length={5}
                value={hamperCode}
                onChange={handleInputChange}
                size="lg"
                radius="md"
                defaultValue=""
                placeholder=""
                styles={{
                  input: {
                    fontSize: "24px",
                    fontWeight: "bold",
                  },
                }}
              />

              <Divider label="OR" labelPosition="center" />
              <QRCodeScanner handleScanResult={handleScanResult} />
            </Stack>
          </>
        </Stack>
      </Paper>
    </Container>
  );
};

export default HamperStatusUpdate;