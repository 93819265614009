import React, { useEffect, useState } from "react";
import {
    Container,
    Paper,
    Title,
    Text,
    Stack,
    Button,
    Group,
    Anchor,
    PinInput,
} from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import {
    sendEmailVerification,
    sendPhoneVerification,
    verifyEmailCode,
    verifyPhoneCode,
} from "../../store/reducers/customer";
import Spinner from "../../components/Spinner/v1/Spinner";

const CustomerValidateV2 = (props) => {
    const dispatch = useDispatch();
    const emailVerified = useSelector((state) => state.customer.isEmailVerified);
    const phoneVerified = useSelector((state) => state.customer.isPhoneNumberVerified);
    const emailVerificationSent = useSelector((state) => state.customer.emailVerificationSent);
    const phoneVerificationSent = useSelector((state) => state.customer.phoneVerificationSent);
    const showEmailSuccess = useSelector((state) => state.customer.showEmailSuccess);
    const showPhoneSuccess = useSelector((state) => state.customer.showPhoneSuccess);
    const emailAddress = useSelector((state) => state.customer.emailAddress);
    const phoneNumber = useSelector((state) => state.customer.phoneNumber);
    const [emailVerifyError, setEmailVerifyError] = useState("");
    const [phoneVerifyError, setPhoneVerifyError] = useState("");

    const [loading, setLoading] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");

    const handleInputChange = (value) => {
        const numericValue = value.replace(/[^0-9]/g, ""); // Allow only numeric values
        setVerificationCode(numericValue);
    };

    const handleVerifyEmail = () => {
        if (verificationCode.trim().length === 4) {
            const verifyEmail = async () => {
                await dispatch(
                    verifyEmailCode({
                        body: { verificationCode: verificationCode },
                        setLoading: setLoading,
                        setError: setEmailVerifyError,
                        setVerificationCode: setVerificationCode,
                    })
                );
            }

            setLoading(true);
            setTimeout(function() {
                verifyEmail()
            }, 10);
        }
    };

    const handleVerifyPhone = () => {
        if (verificationCode.trim().length === 4) {
            const verifyPhone = async () => {
                await dispatch(
                    verifyPhoneCode({
                        body: { verificationCode: verificationCode },
                        setLoading: setLoading,
                        setError: setPhoneVerifyError,
                        setVerificationCode: setVerificationCode,
                    })
                );
            }

            setLoading(true);
            setTimeout(function() {
                verifyPhone()
            }, 10);
        }
    };

    const handleResendEmail = () => {
        const sendEmailVerifyCode = async () => {
            await dispatch(
                sendEmailVerification({
                    setLoading: setLoading,
                })
            )
        }

        setLoading(true);
        setTimeout(function() {
            sendEmailVerifyCode()
        }, 10);
    };

    const handleResendPhone = () => {
        const sendPhoneVerifyCode = async () => {
            await dispatch(
                sendPhoneVerification({
                    setLoading: setLoading,
                })
            )
        }

        setLoading(true);
        setTimeout(function() {
            sendPhoneVerifyCode()
        }, 10);
    };

    useEffect(() => {
        if (emailVerified && phoneVerified && !showPhoneSuccess && !showEmailSuccess) {
            props.nextStep();
        }

        // Automatically send email verification code on email step load
        setTimeout( function () {
            if (!emailVerified && !emailVerificationSent) {
                handleResendEmail();
            }
        }, 100)

        // Automatically send phone verification code on phone step load
        setTimeout( function () {
            if (emailVerified && !phoneVerified && !phoneVerificationSent) {
                handleResendPhone();
            }
        }, 100)
    }, [emailVerified, phoneVerified, showPhoneSuccess, showEmailSuccess, props, emailVerificationSent, phoneVerificationSent]);

    return (
        <>
            {loading && <Spinner />}
            {!emailVerified && (
                <Container size="xs" my="xl">
                    <Paper withBorder shadow="md" radius="md" p="lg">
                        <Stack spacing="md" align="center">
                            <Title order={3}>Email Address Verification</Title>
                            <Text size="sm" c="dimmed" align="center">
                                Enter the 4-digit verification code that was sent to your email address.
                            </Text>
                            <PinInput
                                length={4}
                                size="lg"
                                radius="md"
                                value={verificationCode}
                                onChange={handleInputChange}
                                autoFocus
                                type="number"
                                defaultValue=""
                                placeholder=""
                                styles={{
                                    input: {
                                        fontSize: "24px",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                            <Group position="center">
                                <Button
                                    fullWidth
                                    onClick={handleVerifyEmail}
                                    disabled={verificationCode.length !== 4}
                                >
                                    Verify Account
                                </Button>
                            </Group>
                            <Group position="center">
                                <Text size="sm" c="dimmed">
                                    Didn't receive code?
                                </Text>
                                <Anchor component="button" size="sm" c="blue" onClick={handleResendEmail}>
                                    Resend
                                </Anchor>
                            </Group>
                        </Stack>
                    </Paper>
                </Container>
            )}
            {emailVerified && !phoneVerified && (
                <Container size="xs" my="xl">
                    <Paper withBorder shadow="md" radius="md" p="lg">
                        <Stack spacing="md" align="center">
                            <Title order={3}>Phone Number Verification</Title>
                            <Text size="sm" c="dimmed" align="center">
                                Enter the 4-digit verification code that was sent to your phone number.
                            </Text>
                            <PinInput
                                length={4}
                                size="lg"
                                radius="md"
                                value={verificationCode}
                                onChange={handleInputChange}
                                autoFocus
                                type="number"
                                defaultValue=""
                                placeholder=""
                                styles={{
                                    input: {
                                        fontSize: "24px",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                            <Group position="center">
                                <Button
                                    fullWidth
                                    onClick={handleVerifyPhone}
                                    disabled={verificationCode.length !== 4}
                                >
                                    Verify Account
                                </Button>
                            </Group>
                            <Group position="center">
                                <Text size="sm" c="dimmed">
                                    Didn't receive code?
                                </Text>
                                <Anchor component="button" size="sm" c="blue" onClick={handleResendPhone}>
                                    Resend
                                </Anchor>
                            </Group>
                        </Stack>
                    </Paper>
                </Container>
            )}
        </>
    );
};

export default CustomerValidateV2;