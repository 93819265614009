import React, {useEffect, useState} from 'react';
import Aux from "../../../components/Aux/Aux";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import NotFound from "../../../pages/NotFound/v1/NotFound";
import {useDispatch, useSelector} from "react-redux";
import CustomerHome from "../../../pages/Customer/CustomerHome";
// import CustomerValidate from "../../../pages/Customer/CustomerValidate";
import Spinner from "../../../components/Spinner/v1/Spinner";
import {getCustomerDetails} from "../../../store/reducers/customer";
import HamperValidate from "../../../pages/Customer/HamperValidate";
import PaymentStep from "../../../pages/Customer/PaymentStep";
import CustomerProfile from "../../../pages/Customer/CustomerProfile";
import CustomerSubscription from "../../../pages/Customer/CustomerSubscription";
import CustomerValidateV2 from '../../../pages/Customer/CustomerValidateV2';
import EmptyPage from "../../../pages/EmptyPage";

const CustomerRoute = (props) => {
    const dispatch = useDispatch(),
        navigate = useNavigate(),
        [loading, setLoading] = useState( true ),
        emailVerified = useSelector( (state) => state.customer.isEmailVerified ),
        phoneVerified = useSelector( (state) => state.customer.isPhoneNumberVerified ),
        hamperInfo = useSelector( (state) => state.customer.hampers ),
        subscriptions = useSelector( (state) => state.customer.subscriptions );

    useEffect(() => {
        const customerDetails = async () => {
            await dispatch( getCustomerDetails({ setLoading }) );
        }

        setLoading(true);
        customerDetails().catch( (err) => {
            console.log(err)
            setLoading( false );
        })
    }, [dispatch]);

    const handleRedirectHamper = () => {
        navigate("/customer/hamper/verify")
    }

    const handleCustomerVerify = () => {
        navigate( "/customer/user/verify" );
    }

    const handleCustomerPayment = () => {
        navigate( "/customer/payment" );
    }

    const handleHamperConfirmation = () => {
        navigate( "/customer/payment" );
    }

    if( !loading && (!emailVerified || !phoneVerified) && window.location.pathname !== "/customer/user/verify" ) {
        handleCustomerVerify()
    }
    else if( !loading && emailVerified && phoneVerified && !hamperInfo?.[0]?.isVerified && window.location.pathname !== "/customer/hamper/verify" ) {
        handleRedirectHamper();
    }
    else if ( !loading && emailVerified && phoneVerified && hamperInfo?.[0]?.isVerified && !subscriptions?.length && window.location.pathname !== "/customer/payment" ) {
        handleCustomerPayment();
    }

    // console.log( "email verified " + emailVerified)
    // console.log( "show email success " + showEmailSuccess)

    return (
        <Aux>
            {loading && <Spinner />}
            <Routes>
                { (!emailVerified || !phoneVerified) && <Route path={"/user/verify"} exact element={ <CustomerValidateV2 isLoading={setLoading} nextStep={handleRedirectHamper} screenType={props.screenType} isShortContent={props.isShortContent} /> }/>}
                { emailVerified && phoneVerified && !hamperInfo?.[0]?.isVerified && <Route path={"/hamper/verify"} exact element={ <HamperValidate screenType={props.screenType} success={handleHamperConfirmation} isShortContent={props.isShortContent} /> }/>}
                { emailVerified && phoneVerified && hamperInfo?.[0]?.isVerified && !subscriptions?.length && <Route path={"/payment"} exact element={ <PaymentStep isShortContent={props.isShortContent} /> }/>}

                { emailVerified && phoneVerified && <Route path={"/user/verify"} exact element={ <Navigate to={"/customer"} /> }/>}
                { emailVerified && phoneVerified && hamperInfo?.[0]?.isVerified && <Route path={"/hamper/verify"} exact element={ <Navigate to={"/customer"} /> }/>}
                { emailVerified && phoneVerified && hamperInfo?.[0]?.isVerified && subscriptions?.length && <Route path={"/payment"} exact element={ <Navigate to={"/customer"} /> }/>}

                { emailVerified && phoneVerified &&
                    hamperInfo?.[0]?.isVerified && subscriptions?.length && <Route path={"/profile"} exact element={ <CustomerProfile screenType={props.screenType} isShortContent={props.isShortContent} /> }/> }
                { emailVerified && phoneVerified &&
                    hamperInfo?.[0]?.isVerified && subscriptions?.length && <Route path={"/subscription"} exact element={ <CustomerSubscription screenType={props.screenType} isShortContent={props.isShortContent} /> }/> }
                { emailVerified && phoneVerified &&
                    hamperInfo?.[0]?.isVerified && subscriptions?.length && <Route path={"/"} exact element={ <CustomerHome screenType={props.screenType} isShortContent={props.isShortContent} /> }/> }

                <Route path={"/"} exact element={ <EmptyPage /> } />
                <Route path={"/*"} element={<NotFound/>}/>
            </Routes>
        </Aux>
    );
};

export default CustomerRoute;

