import React, { useState } from 'react';
import {
    Anchor,
    Button,
    Checkbox,
    Container,
    Group,
    Paper,
    PasswordInput,
    Text,
    TextInput,
    Title,
} from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { handleLogin } from '../../../../store/reducers/auth';
import { useNavigate } from 'react-router-dom';
import classes from './Authentication.module.css';

const Authentication = (props) => {
    const error = useSelector((state) => state.auth.error); // Redux error state
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Local form state
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    // Error state for client-side validation
    const [formErrors, setFormErrors] = useState({
        username: false,
        password: false,
    });

    const handleChange = (name, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        // Clear error for the field when input changes
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        // Validate required fields
        const newErrors = {
            username: !formData.username.trim(),
            password: !formData.password.trim(),

        };

        setFormErrors(newErrors);

        // If validation fails, do not proceed
        if (newErrors.username || newErrors.password) {
            return;
        }

        const loginAPICall = async () => {
            await dispatch(
                handleLogin({
                    body: {
                        username: formData.username,
                        password: formData.password,
                    },
                    setLoading: props.isLoading,
                })
            );
        };

        props.isLoading(true);
        setTimeout(() => {
            loginAPICall();
        }, 10);
    };

    const handleSignup = () => {
        navigate('/get-started');
    };

    const handleForgotPassword = () => {
        navigate('/forgot-password');
    };

    return (
        <Container size={420} my={40}>
            <Title align="center" className={classes.authenticationTitle}>
                Welcome back!
            </Title>
            <Text c="dimmed" size="sm" align="center" mt={5}>
                Do not have an account yet?{' '}
                <Anchor size="sm" component="button" onClick={handleSignup}>
                    Create account
                </Anchor>
            </Text>

            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                {error && (
                    <Text c="red" align="center" mb="md">
                        {error.message || 'Invalid credentials. Please try again.'}
                    </Text>
                )}
                <TextInput
                    label="Email or Phone"
                    placeholder="Enter your email or phone"
                    required
                    error={
                        formErrors.username
                            ? 'Email or phone is required'
                            : error?.field === 'username' && error.message
                    }
                    value={formData.username}
                    autoCapitalize="none"
                    spellCheck="false"
                    onChange={(e) => handleChange('username', e.target.value)}
                />
                <PasswordInput
                    label="Password"
                    placeholder="Enter your password"
                    required
                    error={
                        formErrors.password
                            ? 'Password is required'
                            : error?.field === 'password' && error.message
                    }
                    value={formData.password}
                    onChange={(e) => handleChange('password', e.target.value)}
                    mt="md"
                />
                <Group position="apart" mt="lg" justify="space-between">
                    <Checkbox label="Remember me" />
                    <Anchor
                        component="button"
                        size="sm"
                        onClick={handleForgotPassword}>
                        Forgot password?
                    </Anchor>
                </Group>
                <Button fullWidth onClick={handleSubmit} mt="xl">
                    Sign in
                </Button>
            </Paper>
        </Container>
    );
};

export default Authentication;