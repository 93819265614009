import React from "react";
import {
    Container, Text, Center, Card, Group, Button,
} from "@mantine/core";
import classes from './PricingCard.module.css';
import {
    IconBasketSearch,
    IconCalendarX,
    IconHours24,
    IconShoppingBag,
} from "@tabler/icons-react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const PricingCard = (props) => {
    const navigate = useNavigate();
    const isAuthenticated = useSelector( (state) => state.auth.isAuthenticated )
    const role = useSelector( (state) => state.auth.role );
    const redirectUri = isAuthenticated ? `/${role?.toLowerCase()}` : props.redirect;

    const mockData = [
        { label: '1 hamper per week', icon: IconShoppingBag },
        { label: '24 hour cycle', icon: IconHours24 },
        { label: 'Track your hamper progress', icon: IconBasketSearch },
        { label: 'Cancel anytime', icon: IconCalendarX },
    ];

    const features = mockData.map((feature) => (
        <Group key={feature.label} spacing="sm" align="center" className={classes.pricingFeatureItem}>
            <feature.icon size={20} className={classes.pricingIcon} stroke={1.5} />
            <Text size="sm">{feature.label}</Text>
        </Group>
    ));

    return (
        <Container size="xs" my="xl">
            <Center>
                <Card
                    withBorder
                    radius="md"
                    style={{
                        maxWidth: 300, // Set the maximum width of the card
                        width: "100%", // Ensure it adapts to small screens
                    }}
                    className={classes.pricingCard}
                >
                    <Group justify="space-between" mt="xs">
                        <div>
                            <Text fw={500}>The Fresh Standard</Text>
                            <Text fz="xs" c="dimmed">
                                There’s no limit to how many pickup days you can use!
                            </Text>
                        </div>
                    </Group>

                    <Card.Section className={classes.pricingSection} mt="md">
                        <Text fz="sm" c="dimmed" className={classes.pricingLabel} mb="sm">
                            Summary
                        </Text>

                        <Group gap={8} mb={-8}>
                            {features}
                        </Group>
                    </Card.Section>

                    <Card.Section className={classes.pricingSection}>
                        <Group gap={30}>
                            <div>
                                <Text fz="xl" fw={700} style={{ lineHeight: 1 }}>
                                    $68.00
                                </Text>
                                <Text fz="sm" c="dimmed" fw={500} style={{ lineHeight: 1 }} mt={3}>
                                    per month
                                </Text>
                            </div>

                            <Button radius="xl" style={{ flex: 1 }} onClick={() => navigate(redirectUri)}>
                                { props.buttonText || "Get Started!" }
                            </Button>
                        </Group>
                    </Card.Section>
                </Card>
            </Center>
        </Container>
    );
};

export default PricingCard;
