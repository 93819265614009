import React, { useState } from 'react';
import {
  Anchor,
  Group,
  Button,
  Container,
  Paper,
  Text,
  TextInput,
  Title,
  LoadingOverlay,
  Stack,
  Center,
} from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { sendPasswordResetLink } from '../../../../store/reducers/customer';
import { useNavigate } from 'react-router-dom';
import { IconCircleCheck } from "@tabler/icons-react";
import classes from './ForgotPassword.module.css';

const ForgotPassword = (props) => {
  const error = useSelector((state) => state.auth.error); // Redux error state
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState( false );
  const [isSuccess, setSuccess] = useState(false); // Success state

  // Local form state
  const [formData, setFormData] = useState({
    emailAddress: '',
  });

  // Error state for client-side validation
  const [formErrors, setFormErrors] = useState({
    emailAddress: false,
  });

  const handleChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear error for the field when input changes
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    // Validate required fields
    const newErrors = {
      emailAddress: !formData.emailAddress.trim(),
    };

    setFormErrors(newErrors);

    // If validation fails, do not proceed
    if (newErrors.emailAddress) {
      return;
    }

    const sendPasswordResetLinkApiCall = async () => {
      await dispatch(
        sendPasswordResetLink({
          body: {
            emailAddress: formData.emailAddress,
          },
          setLoading: setLoading,
          setSuccess: setSuccess,
        })
      );
    };

    setLoading(true);
    setTimeout(() => {
        sendPasswordResetLinkApiCall();
    }, 10);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <>
      {isSuccess ? (
        <Container size={420} my={40}>
          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <Stack align="center" spacing="lg">
              {/* Replace this with your Checkmark asset if needed */}
              <Center>
                <IconCircleCheck size={88} style={{ color: "#4cd964" }} />
              </Center>
              <Title order={2} align="center">
                Password Reset Link Sent
              </Title>
              <Text size="md" align="center" c="dimmed">
                We've sent a password reset link to your email address. Please check your inbox and follow the instructions to reset your password. If you don't see the email, be sure to check your spam or junk folder.
              </Text>
            </Stack>
          </Paper>
        </Container>
      ) : (
      <Container size={420} my={40}>
        <Title align="center" className={classes.ForgotPasswordTitle}>
          Forgot password?
        </Title>
        <Text c="dimmed" size="sm" align="center" mt={5}>
          Enter your email and we'll send you a link to reset your password
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
          <form onSubmit={handleSubmit}>
              
            {error && (
              <Text c="red" align="center" mb="md">
                {error.message || 'Invalid credentials. Please try again.'}
              </Text>
            )}
            <TextInput
              label="Email address"
              placeholder="Enter your email address"
              required
              error={
                formErrors.emailAddress
                  ? 'Email address is required'
                  : error?.field === 'emailAddress' && error.message
              }
              value={formData.emailAddress}
              autoCapitalize="none"
              spellCheck="false"
              onChange={(e) => handleChange('emailAddress', e.target.value)}
            />
            <Group position="apart" mt="lg" justify="space-between">
              <Text c="dimmed" size="sm" align="center" mt={5}>
                      Remember your password?{' '}
                  <Anchor size="sm" component="button" onClick={handleLogin}>
                      Sign in
                  </Anchor>
              </Text>
            </Group>
            <Button fullWidth type="submit" mt="xl">
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
      )}
    </>
  );
};

export default ForgotPassword;