import React from "react";
import {
  Container,
  Title,
  Text,
  Card,
  Stack,
  List,
  ThemeIcon,
  Grid,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import DefaultFooter from "../../components/Footers/DefaultFooter";

const AboutUs = () => {
  return (
    <>
        <Container size="lg" py="xl">
        {/* Hero Section */}
        <Title
            align="center"
            mb="lg"
            style={{
            fontSize: "2.5rem",
            fontWeight: 900,
            background: "linear-gradient(90deg, #1e90ff, #00bcd4)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            }}
        >
            About Us
        </Title>
        <Text align="center" size="lg" mb="xl" c="dimmed">
            Discover our mission, vision, and values that drive us to deliver exceptional service every day.
        </Text>

        {/* Mission Statement Section */}
        <Card radius="md" p="xl" mb="lg" style={{ background: "#D3D3D3" }}>
            <Title order={2} mb="md" align="center" style={{ color: "var(--blackbox-blue)" }}>
                Mission Statement
            </Title>
            <Text size="md" align="center">
                Our mission is to simplify and improve the lives of our customers by delivering fast, reliable wash and fold
                services that provide convenience and care, giving them more time for what matters most.
            </Text>
        </Card>

        {/* Vision Section */}
        <Card radius="md" p="xl" mb="lg" style={{ background: "#D3D3D3" }}>
            <Title order={2} mb="md" align="center" style={{ color: "var(--blackbox-blue)" }}>
                Vision
            </Title>
            <Text size="md" align="center">
                Our vision is to be the leading laundry service provider for all communities, known for our unmatched speed,
                innovation, reliability, and customer care. We aspire to transform the way people experience laundry,
                making it a hassle-free and enjoyable part of everyday life, while continually enhancing the quality and
                convenience of our services.
            </Text>
        </Card>

        {/* Company Overview Section */}
        <Card shadow="lg" radius="md" p="xl" mb="lg" withBorder>
            <Grid gutter="xl">
            {/* Who We Are */}
            <Grid.Col span={12} md={6}>
                <Stack spacing="sm">
                <Title order={2} style={{ color: "#1e90ff" }}>
                    Who We Are
                </Title>
                <Text size="md">
                    The Origami Company is a dedicated provider of wash and fold service solutions tailored specifically for
                    multi-family communities. We focus on delivering efficient, reliable, and convenient laundry services,
                    ensuring that residents and partners can enjoy a seamless and stress-free experience.
                </Text>
                </Stack>
            </Grid.Col>

            {/* Values */}
            <Grid.Col span={12} md={6}>
                <Stack spacing="sm">
                <Title order={2} style={{ color: "#00bcd4" }}>
                    Values
                </Title>
                <List
                    spacing="sm"
                    size="md"
                    icon={
                    <ThemeIcon color="teal" size={24} radius="xl">
                        <IconCheck size={16} />
                    </ThemeIcon>
                    }
                >
                    <List.Item>
                    <Text fw={500} component="span">
                        Customer-Centricity
                    </Text>
                    : We prioritize the needs of our customers, delivering exceptional service with every wash and fold,
                    ensuring satisfaction and convenience.
                    </List.Item>
                    <List.Item>
                    <Text fw={500} component="span">
                        Efficiency
                    </Text>
                    : We are committed to providing fast, reliable services, constantly seeking ways to improve and
                    streamline our processes for the benefit of our clients.
                    </List.Item>
                    <List.Item>
                    <Text fw={500} component="span">
                        Quality
                    </Text>
                    : We handle each garment with care, maintaining high standards to ensure that every item is returned
                    fresh, clean, and perfectly folded.
                    </List.Item>
                    <List.Item>
                    <Text fw={500} component="span">
                        Innovation
                    </Text>
                    : We embrace continuous improvement, always exploring new methods and technologies to enhance our
                    services and exceed customer expectations.
                    </List.Item>
                    <List.Item>
                    <Text fw={500} component="span">
                        Community
                    </Text>
                    : We believe in building strong relationships within the multi-family communities we serve,
                    contributing to a better quality of life for all residents.
                    </List.Item>
                </List>
                </Stack>
            </Grid.Col>
            </Grid>
        </Card>
        </Container>
        <DefaultFooter />
    </>
  );
};

export default AboutUs;