import {put} from "redux-saga/effects";
import {login, logout, updateAuth} from "../reducers/auth";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import {updateState} from "../reducers/signup";

export function* authCheckAuthenticationSaga( action ) {
    try {
        const postResponse = yield fetch(process.env.REACT_APP_REFRESH_AUTH, {
            method: 'POST',
            credentials: 'include'
        })

        const data = yield postResponse.json();

        if( !postResponse.ok ) {
            const errorMessage = data.message || "Unexpected Error Occurred";
            throw Error(errorMessage);
        }
        yield put(updateAuth({
            "role": data["role"],
            "isAuthenticated": true,
            "error": ""
        }));
    }
    catch (e) {
        yield localStorage.removeItem( "isAuthenticated" );
        yield put( logout() );
    }
}

export function* authLoginUser( action ) {
    try{
        const postResponse = yield fetch(process.env.REACT_APP_AUTH, {
            method: 'POST',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify( action.payload.body )
        })
        const data = yield postResponse.json();

        if( !postResponse.ok ) {
            const errorMessage = data.message || "Unexpected Error Occurred";
            throw Error(errorMessage);
        }

        // const isAuthenticated = true;
        yield put(login({
            "role": data["role"],
        } ));
        yield localStorage.setItem( "isAuthenticated", true);
    }
    catch (e) {
        const error = "The Username and Password provided do not match"
        yield put(updateAuth({
            error,
            "role": "",
            "isAuthenticated": false
        } ));
        yield localStorage.removeItem( "isAuthenticated" );
    }
    yield action.payload.setLoading( false );
}

export function* authLogoutUser( action ) {
    try {
        const postResponse = yield fetch(process.env.REACT_APP_LOGOUT, {
            method: 'POST',
            credentials: 'include'
        })

        if( !postResponse.ok ) {
            const data = yield postResponse.json();
            const errorMessage = data.message || "Unexpected Error Occurred";
            throw Error(errorMessage);
        }

        showNotification({
            title: "Logout successful",
            color: "teal",
            icon: <IconCheck />,
            autoClose: 10000,
        });

        yield put( updateState( {
            successMessage: "",
            error: "",
            usernameError: "",
            addressError: "",
        }))
        yield put( logout() )
        yield localStorage.removeItem( "isAuthenticated" );
    }
    catch (e) {
        showNotification({
            title: "Error",
            message: e.toString(),
            color: "red",
            icon: <IconX />,
            autoClose: 10000,
        });
        yield updateAuth( {errorMessage: e.toString() } )
    }
}