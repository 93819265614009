import React from "react";
import Aux from "../../components/Aux/Aux";
import DefaultFooter from "../../components/Footers/DefaultFooter";
import styled from "styled-components";
import {Accordion, Container, Text, Anchor} from "@mantine/core";
import classes from "./PrivacyPolicy.module.css";

const PrivacyPolicy = (props) => {
    return (
        <Aux>
            <Container size="sm" className={classes.wrapper}>
                <Title size="xl" fw={700} align="center">
                    Our Privacy Policy
                </Title>

                <Text color="dimmed" className={classes.privacyPolicyDesc} align="center" mt="md">
                    The Origami Company ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our website theorigamicompany.com (collectively, "Services"). By accessing or using the Services, you agree to the terms of this Privacy Policy.
                </Text>

                <Accordion variant="separated">
                    <Accordion.Item className={classes.item} value="information-we-collect">
                        <Accordion.Control>
                            <Text size="md" fw={700}>
                                Information We Collect
                            </Text>
                        </Accordion.Control>
                        <Accordion.Panel>
                        <ul>
                            <li>
                                <Text size="sm" fw={700}>Personal Information:</Text>
                                <Text size="sm">
                                    Information that identifies you personally, such as your name, email address, and phone
                                    number, which you provide when registering or using the Services.
                                </Text>
                            </li>
                            <li>
                                <Text size="sm" fw={700}>Usage Data:</Text>
                                <Text size="sm">
                                    Information about how you use the Services, including your IP address, browser
                                    type, operating system, pages visited, and timestamps.
                                </Text>
                            </li>
                            <li>
                                <Text size="sm" fw={700}>Communication Data:</Text>
                                <Text size="sm">
                                    Records of messages sent and received through our Services, including
                                    any data associated with those messages.
                                </Text>
                            </li>
                        </ul></Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

                <Accordion variant="separated">
                    <Accordion.Item className={classes.item} value="how-info-used">
                        <Accordion.Control>
                            <Text size="md" fw={700}>
                                How We Use Your Information?
                            </Text>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <Text size="sm">We may use the information we collect for various purposes, including:</Text>
                            <ul>
                                <li>
                                    <Text size="sm">
                                        To provide and maintain our Services and its functionality
                                    </Text>
                                </li>
                                <li>
                                    <Text size="sm">
                                        To communicate with you, including sending notifications and updates
                                    </Text>
                                </li>
                                <li>
                                    <Text size="sm">
                                        To respond to your inquiries and provide customer support
                                    </Text>
                                </li>
                                <li>
                                    <Text size="sm">
                                        To analyze usage patterns and improve our Services
                                    </Text>
                                </li>
                                <li>
                                    <Text size="sm">
                                        To comply with legal obligations and regulatory requirements
                                    </Text>
                                </li>
                            </ul></Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

                <Accordion variant="separated">
                    <Accordion.Item className={classes.item} value="data-sharing-disclosure">
                        <Accordion.Control>
                            <Text size="md" fw={700}>
                                Data Sharing and Disclosure
                            </Text>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <Text size="sm">We do not sell your personal information to third parties. We may share your information with:</Text>
                            <ul>
                                <li>
                                    <Text size="sm" fw={700}>Service Providers</Text>
                                    <Text size="sm">
                                        Third-party vendors, including Twilio, who assist us in operating the Services and providing services to you, subject to their own privacy policies.
                                    </Text>
                                </li>
                                <li>
                                    <Text size="sm" fw={700}>Legal Compliance:</Text>
                                    <Text size="sm">
                                    We may disclose your information if required by law or in response to valid requests by public authorities.
                                    </Text>
                                </li>
                            </ul></Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

                <Accordion variant="separated">
                    <Accordion.Item className={classes.item} value="data-security">
                        <Accordion.Control className={classes.accordionControl}>
                            <Text size="md" fw={700}>
                                Data Security
                            </Text>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <Text size="sm">We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to protect your information, we cannot guarantee its absolute security.</Text>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

                <Accordion variant="separated">
                    <Accordion.Item className={classes.item} value="your-rights">
                        <Accordion.Control>
                            <Text size="md" fw={700}>
                                Your Rights
                            </Text>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <Text size="sm">Depending on your location, you may have certain rights regarding your personal information, including:</Text>
                            <ul>
                                <li>
                                    <Text size="sm">
                                        The right to access, correct, or delete your information
                                    </Text>
                                </li>
                                <li>
                                    <Text size="sm">
                                        The right to restrict or object to our processing of your information
                                    </Text>
                                </li>
                                <li>
                                    <Text size="sm">
                                        The right to data portability
                                    </Text>
                                </li>
                            </ul>
                        <Text size="sm">To exercise these rights, please contact us using the information provided below.</Text>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

                <Accordion variant="separated">
                    <Accordion.Item className={classes.item} value="changes">
                        <Accordion.Control className={classes.accordionControl}>
                            <Text size="md" fw={700}>Changes to This Privacy Policy</Text>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <Text size="sm">
                                We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                            </Text>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

                <Accordion variant="separated">
                    <Accordion.Item className={classes.item} value="contact-us-privacy">
                        <Accordion.Control className={classes.accordionControl}>
                            <Text size="md" fw={700}>Contact Us</Text>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <Text size="sm">
                                If you have any questions about this Privacy Policy, please contact us at:
                            </Text>
                            <br/>
                            <Text size="sm" fw={700}>The Origami Company</Text>
                            <Text size="sm">
                                12670 Jebbia Ln, Stafford, TX 77477
                            </Text>
                            <Text size="sm">
                                <Anchor href="mailto:admin@theorigamicompany.com">
                                    admin@theorigamicompany.com
                                </Anchor>
                            </Text>
                            </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </Container>
            <DefaultFooter isShortContent={props.isShortContent}/>
        </Aux>
    )
}

export default PrivacyPolicy;

export const ListParagraph = styled.div`
    font-size: 18px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    margin: 10px 0;

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 13px;
    }
`;

const Section = styled.div`
    padding: 10px 100px;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const Title = styled.h2`
    font-weight: bolder;
`;

const Absolute = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 768px) {
        width: 80%;
    }
`;
