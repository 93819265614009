import { IconBrandInstagram, IconBrandTwitter, IconBrandYoutube } from '@tabler/icons-react';
import {
  ActionIcon,
  Button,
  Paper,
  SimpleGrid,
  Text,
  Textarea,
  TextInput,
  Title,
  Group,
} from '@mantine/core';
import { ContactIconsList } from './ContactIcons';
import classes from './ContactUs.module.css';
import Aux from "../../components/Aux/Aux";
import DefaultFooter from "../../components/Footers/DefaultFooter";
import {useRef, useState} from "react";
import { contactForm } from '../../store/reducers/customer.js';
import {useDispatch} from "react-redux";

const social = [IconBrandTwitter, IconBrandYoutube, IconBrandInstagram];

const ContactUs = (props) => {
    const emailRef = useRef(),
        nameRef = useRef(),
        messageRef = useRef(),
        [emailError, setEmailError] = useState(false),
        [nameError, setNameError] = useState(false),
        [messageError, setMessageError] = useState(false),
        [formError, setFormError] = useState(false),
        dispatch = useDispatch();

    const icons = social.map((Icon, index) => (
        <ActionIcon key={index} size={28} className={classes.contactUsSocial} variant="transparent">
            <Icon size={22} stroke={1.5} />
        </ActionIcon>
    ));

    const handleSubmit = (event) => {
        const email = emailRef.current?.value;
        const name = nameRef.current?.value;
        const message = messageRef.current?.value;
        let error = false;
        setEmailError( false );
        setMessageError( false );
        setFormError( false );

        if( !email || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test( email ) ) {
            setEmailError( true );
            error = true;
        }
        // Validate name
        if (!name || name.trim().length === 0) {
            setNameError(true);
            error = true;
        }
        if( !message || message.trim().length === 0) {
            setMessageError( true );
            error = true;
        }

        // If validation fails, do not proceed
        if( error ) {
            event.preventDefault();
        }
        else {
            const contactUsCall = async () => {
                await dispatch(
                    contactForm({
                        body: {
                            emailAddress: email,
                            name: name,
                            message: message,
                        },
                        setContactError: setFormError,
                        setLoading: props.setLoading,
                        ref: {
                            emailRef: emailRef,
                            nameRef: nameRef,
                            messageRef: messageRef
                        }
                    })
                );
            };

            props.setLoading(true);
            setTimeout(() => {
                contactUsCall();
            }, 10);
        }
    }

    return (
        <Aux>
            <div className={classes.contactUsOuterWrap}>
                <div className={classes.contactUsInnerWrap}>
                    <div className={classes.contactUsWrapper}>
                        <SimpleGrid
                            cols={2}
                            spacing="xl"
                            breakpoints={[{maxWidth: 768, cols: 1, spacing: 'md'}]} // Responsive behavior
                            className={classes.contactUsTable}
                        >
                            {/* Contact Info Section */}
                            <div>
                                <Title className={classes.contactUsTitle}>Contact us</Title>
                                <Text className={classes.contactUsDescription} mt="sm" mb={30}>
                                    Leave your email and we will get back to you within 24 hours
                                </Text>

                                <ContactIconsList/>
                            </div>

                            {/* Form Section */}
                            <Paper withBorder shadow="md" p={30} radius="md">
                                <TextInput
                                    label="Email"
                                    placeholder="your@email.com"
                                    required
                                    error={emailError ? "Please provide a valid email" : null }
                                    ref={emailRef}
                                    classNames={{
                                        input: classes.contactUsInput,
                                        label: classes.contactUsInputLabel,
                                    }}
                                    onChange={() => setEmailError(false)}
                                />
                                <TextInput
                                    label="Name"
                                    placeholder="John Doe"
                                    required
                                    error={nameError ? "Please provide a name" : null }
                                    mt="md"
                                    ref={nameRef}
                                    classNames={{
                                        input: classes.contactUsInput,
                                        label: classes.contactUsInputLabel,
                                    }}
                                    onChange={() => setNameError(false)}
                                />
                                <Textarea
                                    required
                                    label="Your message"
                                    placeholder="I want to order your goods"
                                    minRows={8}
                                    error={ messageError ? "Please let us know how we can be of service" : null }
                                    mt="lg"
                                    ref={messageRef}
                                    onChange={() => setMessageError(false)}
                                    classNames={{
                                        input: classes.contactUsInput,
                                        label: classes.contactUsInputLabel,
                                    }}
                                />
                                <Group justify="flex-end" mt="md">
                                    <Button className={classes.contactUsControl} onClick={(event) => handleSubmit(event) }>Send message</Button>
                                </Group>
                                { formError &&
                                    <Text className={classes.errorMessage} mt="sm" mb={30}>
                                        Something went wrong. Please try again or call us or send us an email directly at <a href={"mailto:admin@theorigamicompany.com"}>admin@theorigamicompany.com</a>
                                    </Text>
                                }
                            </Paper>
                        </SimpleGrid>
                    </div>
                </div>
            </div>

            <DefaultFooter />
        </Aux>
    );
}

export default ContactUs;
