import React from "react";
import {
    Title,
    Container,
    Text,
    Center, ThemeIcon, SimpleGrid, Card, Group,
} from "@mantine/core";
import classes from './GetStarted.module.css';
import {IconClock24, IconShoppingBag, IconShoppingBagCheck, IconUserPlus} from "@tabler/icons-react";
import PricingCard from "../../components/Reused/PricingCard";
import DefaultFooter from "../../components/Footers/DefaultFooter";
import Aux from "../../components/Aux/Aux";

const GetStarted = () => {
    const residentFeatures = [
        {
            icon: IconUserPlus,
            title: 'Sign up',
            description: 'Verify that The Origami Company’s service is available at your building and sign up.',
        },
        {
            icon: IconShoppingBagCheck,
            title: 'Verify hamper',
            description: 'Receive your hamper and confirm its receipt.',
        },
        {
            icon: IconShoppingBag,
            title: 'Fill hamper',
            description:
                'Fill the hamper to the brim and leave it at the designated area on pickup days.',
        },
        {
            icon: IconClock24,
            title: 'Enjoy fresh laundry',
            description:
                'Your clean, folded laundry is delivered the next day. There’s no limit to how many pickup days you can utilize!',
        },
    ];

    const residentItems = residentFeatures.map((feature) => (
        <div key={feature.title}>
            <ThemeIcon
                size={44}
                radius="md"
                variant="gradient"
                gradient={{ deg: 133, from: 'blue', to: 'cyan' }}
            >
                <feature.icon size={26} stroke={1.5} />
            </ThemeIcon>
            <Text fz="lg" mt="sm" fw={500}>
                {feature.title}
            </Text>
            <Text c="dimmed" fz="sm">
                {feature.description}
            </Text>
        </div>
    ));

    return (
        <Aux>
            <Container size="xl" my="xl">
                <Container className={classes.wrapper}>
                    <Center>
                        <Title className={classes.title} order={2} mb="md">Effortless Laundry, Simplified</Title>
                    </Center>

                    <Container size={560} p={0}>
                        <Text c="dimmed">
                            Discover how our seamless process transforms your laundry routine. Sign up, fill your hamper, and enjoy fresh, folded clothes delivered to your door—it’s that simple!
                        </Text>
                    </Container>

                    <SimpleGrid
                        mt={60}
                        cols={{ base: 1, sm: 1, md: 4 }}
                        spacing={{ base: 'xl', md: 50 }}
                        verticalSpacing={{ base: 'xl', md: 50 }}
                    >
                        {residentItems}
                    </SimpleGrid>
                </Container>

                <PricingCard buttonText={"Signup"} redirect={"/signup"} />

            </Container>
            <DefaultFooter />
        </Aux>
    );
};

export default GetStarted;