import React from "react";
import DefaultFooter from "../../../components/Footers/DefaultFooter";
import styled from "styled-components";
import {Accordion, Anchor, Container} from "@mantine/core";
import classes from "./FAQ.module.css";
import {Link, useNavigate} from "react-router-dom";
import Aux from "../../../components/Aux/Aux";

const FAQ = (props) => {
    const navigate = useNavigate();

    return (
        <Aux>
            <Container size="sm" className={classes.wrapper}>
                <Title align="center" className={classes.title}>
                    Frequently Asked Questions
                </Title>

                <Accordion variant="separated">
                    <Accordion.Item className={classes.item} value="reset-password">
                        <Accordion.Control>Am I eligible to sign up?</Accordion.Control>
                        <Accordion.Panel>Eligibility depends on whether your building is enrolled in our service. Please check with your building management to confirm. If your community is not yet participating and you’re interested in our services, you can express your interest by submitting a request <Link to={"/contact-us"}>[here]</Link>!</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="another-account">
                        <Accordion.Control>How does it work?</Accordion.Control>
                        <Accordion.Panel>Once your eligibility is confirmed, you’re just a few steps away from enjoying hassle-free laundry! <Anchor size="sm" component="button" onClick={() => navigate("/get-started")}>Sign up</Anchor>, and we’ll deliver a hamper to your service address. Simply confirm receipt by scanning the QR code or entering the hamper code. Then, pack your hamper to the brim with laundry and leave it in the designated area on pickup days. The next day, your laundry will be returned, freshly washed and neatly folded, right to the same spot!</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="newsletter">
                        <Accordion.Control>What is the turnaround time?</Accordion.Control>
                        <Accordion.Panel>Enjoy the convenience of next-day service! Your laundry will be delivered back to you, freshly washed and perfectly folded, in just 24 hours.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="credit-card">
                        <Accordion.Control>What are “Pick-Up” days?</Accordion.Control>
                        <Accordion.Panel>To seamlessly fit into your busy schedule and provide a reliable and consistent pick-up service, we operate on a pick-up day system. These are specific days when our drivers will come by to collect your hamper. For your convenience, please check with your property manager for your community’s designated pick-up days or refer to your account home page.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="notified">
                        <Accordion.Control>Will I be notified?</Accordion.Control>
                        <Accordion.Panel>Absolutely! You’ll receive a text reminder the day before your designated pick-up day and a 1-hour time frame on the day of pick-up to ensure you’re prepared.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="scheduling">
                        <Accordion.Control>Am I able to schedule an on-demand pick up?</Accordion.Control>
                        <Accordion.Panel>At this time, we do not offer an on-demand service.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="dry-cleaning">
                        <Accordion.Control>Do you offer dry cleaning?</Accordion.Control>
                        <Accordion.Panel>We do not offer a dry cleaning service at this time.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="contracts">
                        <Accordion.Control>Are there any contracts or long-term commitments?</Accordion.Control>
                        <Accordion.Panel>Nope! You can pause or cancel your subscription anytime on the accounts page—no long-term commitments.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="process">
                        <Accordion.Control>What is your laundry process?</Accordion.Control>
                        <Accordion.Panel>We wash all clothes with cold water and dry them using low heat. This ensures a thorough clean while using the safest methods to protect your garments.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="products">
                        <Accordion.Control>Which products are used to wash my garments?</Accordion.Control>
                        <Accordion.Panel>We use an unscented, hypoallergenic detergent that’s free of dyes and perfumes to ensure a gentle but effective clean for your garments.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="personalization">
                        <Accordion.Control>Can I personalize how I have my laundry washed?</Accordion.Control>
                        <Accordion.Panel>We do not offer customization at this time.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="hangers">
                        <Accordion.Control>Can I have some of my clothes returned on a hanger?</Accordion.Control>
                        <Accordion.Panel>We do not offer hanging clothes. All clothes will be returned neatly folded.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="mixins">
                        <Accordion.Control>Will my clothes be washed with other customer’s clothes?</Accordion.Control>
                        <Accordion.Panel>Never! We will always wash and dry each hamper individually in separate machines.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="professionals">
                        <Accordion.Control>Who will wash my garments?</Accordion.Control>
                        <Accordion.Panel>Your garments are handled by our full-time professionals, who wash, dry, and ensure everything is properly cared for.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="separate-whites">
                        <Accordion.Control>Will you separate my white and colored garments or check if there are any items in pockets?</Accordion.Control>
                        <Accordion.Panel>No, we wash all loads exactly as they’re received in the hamper.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="bedding">
                        <Accordion.Control>Can I send bedding?</Accordion.Control>
                        <Accordion.Panel>Anything machine washable that fits in the hamper may be sent!</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="shoes">
                        <Accordion.Control>Can I send shoes?</Accordion.Control>
                        <Accordion.Panel>Machine washable shoes are accepted.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item className={classes.item} value="garments-returned">
                        <Accordion.Control>How will my garments be returned?</Accordion.Control>
                        <Accordion.Panel>Your garments will be neatly washed, folded, placed in polybags, and returned inside the hamper.</Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </Container>
            <DefaultFooter isShortContent={props.isShortContent}/>
        </Aux>
    )
}

export default FAQ;

export const Question = styled.div`
    font-size: 18px;
    line-height: 16px;
    text-align: left;
    padding: 10px;
    background-color: #63B0C1;
    font-weight: bolder;

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 13px;
    }
`;

export const Answer = styled.div`
    font-size: 18px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    margin: 10px 0;
    padding: 10px;

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 13px;
    }
`;

const Section = styled.div`
    padding: 10px 100px;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const Title = styled.h2`
    font-weight: bolder;
`;

const Absolute = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 768px) {
        width: 80%;
    }
`;
