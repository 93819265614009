import React from "react";
import { useSelector } from "react-redux";
import { Container, Title, Text, Paper} from "@mantine/core";
import Verification from "./Verification";
import { Stack } from "react-bootstrap";

const HamperVerification = (props) => {
  const error = useSelector((state) => state.hamper.error);
  const successMessage = useSelector((state) => state.hamper.successMessage);

  return (
    <Container size="xs" my="xl">
      <Paper withBorder shadow="md" radius="md" p="lg">
        <Stack spacing="md" align="center">
            <Title order={3}>Verify Your Hamper</Title>
            <Text size="sm" c="dimmed" align="center" mb="md">
            Please enter your hamper code or scan the QR code to verify your hamper.
            </Text>
            <Verification setLoading={props.setLoading} screenType={props.screenType} />
        </Stack>
      </Paper>
    </Container>
  );
};

export default HamperVerification;