import React from "react";
import { Container, Paper, Title, Text, Button, Stack, Center } from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons-react";

const PaymentStep = () => {
  const handleCheckoutSession = () => {
    window.location.href = process.env.REACT_APP_CUSTOMER_CHECKOUT_PAYMENT;
  };

  return (
    <Container size="xs" my="xl">
      <Paper withBorder shadow="md" radius="md" p="lg">
        <Stack align="center" spacing="lg">
          {/* Replace this with your Checkmark asset if needed */}
          <Center>
            <IconCircleCheck size={88} style={{ color: "#4cd964" }} />
          </Center>
          <Title order={2} align="center">
            Hamper Verified
          </Title>
          <Text size="md" align="center" c="dimmed">
            Your Hamper was verified successfully!
          </Text>
          <Button size="md" onClick={handleCheckoutSession}>
            Proceed to Payment
          </Button>
        </Stack>
      </Paper>
    </Container>
  );
};

export default PaymentStep;