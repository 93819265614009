import React from "react";
import { Card, Image, Text, Title, Button, Container, Center, Space } from "@mantine/core";
import HamperImg from "../../../assets/images/images/Hamper.png";

const HamperShipped = (props) => {
  return (
    <Container size="xs" py="xl">
      <Card shadow="sm" padding="xl" radius="md" withBorder>
        <Center>
          <Image src={HamperImg} width={120} alt="Hamper" />
        </Center>

        <Space h="md" />

        <Title order={2} align="center">
          Your Hamper has been Shipped!
        </Title>

        <Text c="dimmed" align="center" mt="sm">
          Your laundry hamper is on its way. Please verify once it arrives to ensure everything is in
          order. Thank you for choosing The Origami Company!
        </Text>

        <Space h="xl" />

        <Center>
          <Button onClick={props.acknowledge}>
            Acknowledge
          </Button>
        </Center>
      </Card>
    </Container>
  );
};

export default HamperShipped;