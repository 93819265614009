import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Container, Button, Title, Text, Box, Paper, PasswordInput, Group, Center, Progress, LoadingOverlay } from '@mantine/core';
import { IconCheck, IconX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { resetPassword } from '../../../../store/reducers/customer';

const requirements = [
  { re: /[0-9]/, label: "Includes number" },
  { re: /[a-z]/, label: "Includes lowercase letter" },
  { re: /[A-Z]/, label: "Includes uppercase letter" },
  { re: /[^a-zA-Z0-9]/, label: "Includes special symbol" },
];

const getStrength = (password) => {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
  if (!requirement.re.test(password)) {
      multiplier += 1;
      }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
};

const PasswordRequirement = ({ meets, label }) => (
  <Text component="div" c={meets ? "teal" : "red"} mt={5} size="sm">
      <Center inline>
      {meets ? <IconCheck size={14} stroke={1.5} /> : <IconX size={14} stroke={1.5} />}
      <Box ml={7}>{label}</Box>
      </Center>
  </Text>
);

const ResetPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
      password: "",
      confirmPassword: "",
    });

    const token = searchParams.get('token');

    const handleSubmit = (event) => {
      event.preventDefault(); // Prevent default form submission behavior
  
      const resetPasswordApiCall = async () => {
        await dispatch(
          resetPassword({
            body: {
              resetToken: token,
              newPassword: formData.password
            },
            setLoading: setIsLoading,
            setIsSuccess: setIsSuccess,
          })
        );
      };
  
      setIsLoading(true);
      setTimeout(() => {
        resetPasswordApiCall();
      }, 10);
    };

    // Navigate to /login when password reset is successful
    useEffect(() => {
      if (isSuccess) {
        navigate("/login");
      }
    }, [isSuccess, navigate]);

    const handleChange = (name, value) => {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
  
      // Clear errors dynamically as the user types
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    };

    const strength = getStrength(formData.password);
    const passwordChecks = requirements.map((req, index) => (
      <PasswordRequirement key={index} label={req.label} meets={req.re.test(formData.password)} />
    ));
    const passwordBars = Array(4)
      .fill(0)
      .map((_, index) => (
        <Progress
          value={
            formData.password.length > 0 && index === 0
              ? 100
              : strength >= ((index + 1) / 4) * 100
              ? 100
              : 0
          }
          color={strength > 80 ? "teal" : strength > 50 ? "yellow" : "red"}
          key={index}
          size={4}
        />
      ));

    return (
      <Container size={420} my={40}>
        <Title align="center">
            Reset password
        </Title>
        <Text c="dimmed" size="sm" align="center" mt={5}>
            Enter a new password
        </Text>
        <Box pos="relative">
          <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <>
              <PasswordInput
                  label="New password"
                  placeholder="Enter your password"
                  value={formData.password}
                  onChange={(e) => handleChange("password", e.target.value)}
                  error={errors.password}
                  required
              />
              <Group gap={5} grow mt="xs" mb="md">
                  {passwordBars}
              </Group>
              <PasswordRequirement label="Has at least 6 characters" meets={formData.password.length > 5} />
                {passwordChecks}
              <PasswordInput
                  label="Confirm new password"
                  placeholder="Confirm your password"
                  value={formData.confirmPassword}
                  onChange={(e) => handleChange("confirmPassword", e.target.value)}
                  error={errors.confirmPassword}
                  required
                  mt="md"
              />
              <Group position="right" mt="xl">
                <Button fullWidth onClick={handleSubmit}>Submit</Button>
              </Group>
              {errors.apiError && (
                <Text c="red" size="sm" mt="md">
                  {errors.apiError}
                </Text>
              )}
            </>
          </Paper>
        </Box>
      </Container>
    );
};

export default ResetPassword;