import { createSlice } from '@reduxjs/toolkit';

export const signupSlice = createSlice({
    name: 'signup',
    initialState: {
        successMessage: "",
        error: "",
        addressError: "",
        usernameError: "",
        promotionalCode: "",
        codeValid: false,
    },
    reducers: {
        updateState: (state, action) => {
            state.error = action.payload.error;
            state.successMessage = action.payload.successMessage;
            state.usernameError = action.payload.usernameError || "";
            state.addressError = action.payload.addressError || "";
        },
        signup: () => {},
        addressValidation: () => {},
        usernameValidation: () => {},
        errorSignupRefresh: () => {},
        validatePromotionalCode: (state, action) => {
            state.codeValid = action.payload.codeValid || false;
            state.promotionalCode = action.payload.promotionalCode || "";
        },
        callValidatePromotionalCode: () => {}
    },
})

export const {
    signup,
    updateState,
    addressValidation,
    usernameValidation,
    errorSignupRefresh,
    validatePromotionalCode,
    callValidatePromotionalCode
} = signupSlice.actions

export default signupSlice.reducer;
