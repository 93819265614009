import { IconBrandInstagram, IconBrandTwitter, IconBrandYoutube } from '@tabler/icons-react';
import { ActionIcon, Container, Group, Text, Image } from '@mantine/core';
import Logo from "../../assets/images/logos/logoDesktop.svg"
import classes from './Footer.module.css';

const DefaultFooter = () => {

    const data = [
        {
          title: 'Company',
          links: [
            { label: 'About', link: '/about' },
            { label: 'Pricing', link: '/pricing' },
            { label: 'Locations', link: '/locations' }
          ],
        },
        {
          title: 'Connect',
          links: [
            { label: 'Contact Us', link: '/contact-us' },
          ],
        },
        {
          title: 'More',
          links: [
            { label: 'Terms', link: '/terms' },
            { label: 'Privacy', link: '/privacy' },
            { label: 'About', link: '/about' },
          ],
        },
      ];

  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <Text
        key={index}
        className={classes.footerLink}
        component="a"
        href={link.link}
      >
        {link.label}
      </Text>
    ));

    return (
      <div className={classes.footerWrapper} key={group.title}>
        <Text className={classes.footerTitle}>{group.title}</Text>
        {links}
      </div>
    );
  });

  return (
    <footer className={classes.footer}>
      <Container className={classes.footerInner}>
        <div className={classes.footerLogo}>
          <Image
                radius="md"
                h="30"
                fit="contain"
                src={Logo}
            />
        </div>
        <div className={classes.footerGroups}>{groups}</div>
      </Container>
      <Container className={classes.afterFooter}>
        <Text c="dimmed" size="sm">
          Copyright © 2025 The Origami Company. All rights reserved.
        </Text>
      </Container>
    </footer>
  );
}

export default DefaultFooter;