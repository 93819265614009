import { 
  IconUserPlus,
  IconShoppingBagCheck,
  IconShoppingBag,
  IconClock24,
  IconGymnastics,
  IconHomeStar,
  IconCircleDotted
} from '@tabler/icons-react';
import { Tabs, Grid, SimpleGrid, Text, ThemeIcon, Container, Title, Center } from '@mantine/core';
import classes from './HowItWorks.module.css';

const residentFeatures = [
  {
    icon: IconUserPlus,
    title: 'Sign up',
    description: 'Verify that The Origami Company’s service is available at your building and sign up.',
  },
  {
    icon: IconShoppingBagCheck,
    title: 'Verify hamper',
    description: 'Receive your hamper and confirm its receipt.',
  },
  {
    icon: IconShoppingBag,
    title: 'Fill hamper',
    description:
      'Fill the hamper to the brim and leave it at the designated area on pickup days.',
  },
  {
    icon: IconClock24,
    title: 'Enjoy fresh laundry',
    description:
      'Your clean, folded laundry is delivered the next day. There’s no limit to how many pickup days you can utilize!',
  },
];

const partnerFeatures = [
  {
    icon: IconCircleDotted, // Replace with the same style as Residents
    title: 'Full service',
    description: 'Once signed up, we provide a full service for implementation.',
  },
  {
    icon: IconGymnastics, // Replace with the same style as Residents
    title: 'Flexible',
    description:
      'You do not have to adjust any of your day-to-day operations as we take care of everything from supplying marketing materials to customer service.',
  },
  {
    icon: IconHomeStar, // Replace with the same style as Residents
    title: 'Best service',
    description:
      'Can be confident in us to provide the best and friendliest service, to both your tenants and management.',
  },
];

const HowItWorks = () => {
  const residentItems = residentFeatures.map((feature) => (
    <div key={feature.title}>
      <ThemeIcon
        size={44}
        radius="md"
        variant="gradient"
        gradient={{ deg: 133, from: 'blue', to: 'cyan' }}
      >
        <feature.icon size={26} stroke={1.5} />
      </ThemeIcon>
      <Text fz="lg" mt="sm" fw={500}>
        {feature.title}
      </Text>
      <Text c="dimmed" fz="sm">
        {feature.description}
      </Text>
    </div>
  ));

  const partnerItems = partnerFeatures.map((feature) => (
    <div key={feature.title}>
      <ThemeIcon
        size={44}
        radius="md"
        variant="gradient"
        gradient={{ deg: 133, from: 'blue', to: 'cyan' }}
      >
        <feature.icon size={26} stroke={1.5} />
      </ThemeIcon>
      <Text fz="lg" mt="sm" fw={500}>
        {feature.title}
      </Text>
      <Text c="dimmed" fz="sm">
        {feature.description}
      </Text>
    </div>
  ));

  return (
    <Container size="md" my="xl" id="how-it-works">
      <Tabs
        variant="unstyled"
        defaultValue="residents"
        classNames={{
          tabsList: classes.tabsList,
          tab: classes.tab,
          tabActive: classes.activeTab,
        }}
      >
        <Tabs.List grow>
          <Tabs.Tab value="residents">Residents</Tabs.Tab>
          <Tabs.Tab value="partners">Partners</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="residents">
          <Container className={classes.wrapper}>
            <Center>
              <Title className={classes.title} order={2} mb="md">Effortless Laundry, Simplified</Title>
            </Center>

            <Container size={560} p={0}>
              <Text c="dimmed">
              Discover how our seamless process transforms your laundry routine. Sign up, fill your hamper, and enjoy fresh, folded clothes delivered to your door—it’s that simple!
              </Text>
            </Container>

            <SimpleGrid
              mt={60}
              cols={{ base: 1, sm: 1, md: 4 }}
              spacing={{ base: 'xl', md: 50 }}
              verticalSpacing={{ base: 'xl', md: 50 }}
            >
              {residentItems}
            </SimpleGrid>
          </Container>
        </Tabs.Panel>

        <Tabs.Panel value="partners">
          <Container className={classes.wrapper}>
            <Center>
              <Title className={classes.title} order={2} mb="md">Partnering Made Simple</Title>
            </Center>

            <Container size={560} p={0}>
              <Text c="dimmed">
              Join our hassle-free partnership program. From seamless implementation to comprehensive customer service, we handle it all—allowing you to focus on your operations while we deliver exceptional service to your tenants and management.
              </Text>
            </Container>

            <SimpleGrid
              mt={60}
              cols={{ base: 1, sm: 1, md: 3 }}
              spacing={{ base: 'xl', md: 50 }}
              verticalSpacing={{ base: 'xl', md: 50 }}
            >
              {partnerItems}
            </SimpleGrid>
          </Container>
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};

export default HowItWorks;