import {put} from "redux-saga/effects";
import {updateHamper} from "../reducers/hamper";
import {logout} from "../reducers/auth";
import {updateCustomerHamperVerification} from "../reducers/customer";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";

export function* hamperTrackingUpdate( action ) {
    try {
        const postResponse = yield fetch(process.env.REACT_APP_UPDATE_TRACKING, {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify( action.payload.body )
        })

        if (!postResponse.ok) {
            if( postResponse.status === 403 ) {
                yield put( logout() )
            }
            const data = yield postResponse.json();
            const errorMessage = data.message || "Something went wrong when updating the Tracking for this hamper";
            throw Error(errorMessage);
        }
        yield action.payload.setHamperCode("");
        showNotification({
            title: "Hamper status updated",
            message: "Hamper status was successfully updated.",
            color: "teal",
            icon: <IconCheck />,
            autoClose: 10000,
        });

        yield put(updateHamper({"error": "", "successMessage": "hamper " + action.payload.body.hamperCode + " was updated successfully" }));
    }
    catch (e) {
        const error = e.toString();
        yield put(updateHamper({error, "successMessage": ""}));
        showNotification({
            title: "Error",
            message: e.toString(),
            color: "red",
            icon: <IconX />,
            autoClose: 10000,
        });
    }
    yield action.payload.setLoading( false );
}

export function* hamperVerify( action ) {
    try {
        const postResponse = yield fetch(process.env.REACT_APP_CUSTOMER_VALIDATE_HAMPER_VERIFICATION, {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type":'application/json',
                "Accept": 'application/json',
            },
            body: JSON.stringify( action.payload.body )
        })

        if (!postResponse.ok) {
            if( postResponse.status === 403 ) {
                yield put( logout() );
            }
            const data = yield postResponse.json();
            const errorMessage = data.message || "Something went wrong when updating the Tracking for this hamper";
            throw Error(errorMessage);
        }

        yield put(updateHamper({"error": "", "successMessage": "hamper " + action.payload.body.hamperCode + " was verified successfully" }));
        yield put(updateCustomerHamperVerification({hamperCode: action.payload.body.hamperCode}));
        showNotification({
            title: "Hamper verified!",
            message: "Hamper verification successful.",
            color: "teal",
            icon: <IconCheck />,
            autoClose: 10000,
        });
    }
    catch (e) {
        const error = e.toString();
        yield put(updateHamper({error, "successMessage": ""}));
        showNotification({
            title: "Error",
            message: e.toString(),
            color: "red",
            icon: <IconX />,
            autoClose: 10000,
        });
    }
    // yield action.payload.setInput(prevState => ({
    //     ...prevState,
    //     hamperCode: ""
    // }));
    // yield action.payload.setIndex( -1 );
    yield action.payload.loading( false );
}
