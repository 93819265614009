import React, { useEffect, useState } from "react";
import { Table, Text, Button, Title, Container, Paper, Anchor } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addressSearch } from "../../store/reducers/employee";
import { IconArrowLeft } from "@tabler/icons-react";
import Spinner from "../../components/Spinner/v1/Spinner";

const EmployeeAddressList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useSelector( (state) => state.auth.role );
  const dashboadUri = `/${role?.toLowerCase()}`;
  const [loading, setLoading] = useState(false);
  const [unique, setUnique] = useState(true);
  const [selectedAddressText, setSelectedAddressText] = useState("");
  const [selectedAddressArray, setSelectedAddressArray] = useState([]);
  const uniqueAddresses = useSelector((state) => state.employee.uniqueAddresses);
  const pendingDeliveries = useSelector((state) => state.employee.pendingDeliveries);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      dispatch(addressSearch({ setLoading }));
    };
    fetchData();
  }, [dispatch]);

  const handleUniqueClick = (address) => {
    setUnique(false);
    setSelectedAddressText(
      `${address.addressLine1}, ${address.city}, ${address.state} ${address.zipCode}`
    );
    const selected = pendingDeliveries.filter(
      (item) =>
        item.addressLine1 === address.addressLine1 &&
        item.city === address.city &&
        item.zipCode === address.zipCode
    );
    setSelectedAddressArray(selected);
  };

  const uniqueAddressRows = uniqueAddresses.map((address, index) => (
    <Table.Tr key={index} onClick={() => handleUniqueClick(address)} style={{ cursor: "pointer" }}>
      <Table.Td>{address.addressLine1}</Table.Td>
      <Table.Td>{address.city}</Table.Td>
      <Table.Td>{address.state}</Table.Td>
      <Table.Td>{address.zipCode}</Table.Td>
    </Table.Tr>
  ));

  const pendingDeliveryRows = selectedAddressArray.map((delivery, index) => (
    <Table.Tr key={index}>
      <Table.Td>{delivery.hamperCode}</Table.Td>
      <Table.Td>{delivery.addressLine2}</Table.Td>
      <Table.Td>{delivery.customerFirstName}</Table.Td>
      <Table.Td>{delivery.customerLastName}</Table.Td>
      <Table.Td>
        <a href={`tel:${delivery.phoneNumber}`}>{delivery.phoneNumber}</a>
      </Table.Td>
      <Table.Td>
        {Math.floor(delivery.cycleDurationInMin / 60)}hr {delivery.cycleDurationInMin % 60}min
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
        {loading && <Spinner />}
        <Container size="lg" my="lg">
        <Anchor
            onClick={() => navigate(dashboadUri)}
            c="blue"
            size="sm"
            mb="lg"
            underline
            style={{ display: "inline-flex", alignItems: "center" }}
        >
            <IconArrowLeft size={16} style={{ marginRight: 8 }} />
            Back to dashboard
        </Anchor>
        <Paper withBorder shadow="md" p={30} mt={30} mb={30} radius="md">
        {unique ? (
            <div>
            <Title order={3}>Unique Addresses</Title>
            <Text size="sm" c="dimmed">
                Click on an address to view its deliveries
            </Text>
            <Table highlightOnHover striped withBorder>
                <Table.Thead>
                <Table.Tr>
                    <Table.Th>Address</Table.Th>
                    <Table.Th>City</Table.Th>
                    <Table.Th>State</Table.Th>
                    <Table.Th>Zip Code</Table.Th>
                </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{uniqueAddressRows}</Table.Tbody>
            </Table>
            </div>
        ) : (
            <div>
            <Title order={3}>Pending Deliveries for:</Title>
            <Title order={4}>{selectedAddressText}</Title>
            <Button
                variant="outline"
                size="xs"
                onClick={() => setUnique(true)}
                mt={10}
                mb={10}
            >
                Back to Address List
            </Button>
            <Table highlightOnHover striped withBorder>
                <Table.Thead>
                <Table.Tr>
                    <Table.Th>Hamper Code</Table.Th>
                    <Table.Th>Unit</Table.Th>
                    <Table.Th>First Name</Table.Th>
                    <Table.Th>Last Name</Table.Th>
                    <Table.Th>Phone</Table.Th>
                    <Table.Th>Duration</Table.Th>
                </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{pendingDeliveryRows}</Table.Tbody>
            </Table>
            </div>
        )}
        </Paper>
        </Container>
    </>
  );
};

export default EmployeeAddressList;