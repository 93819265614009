import { takeEvery } from "redux-saga/effects";
import {authCheckAuthenticationSaga, authLoginUser, authLogoutUser} from "./auth";
import {checkAuthentication, handleLogin, handleLogout} from "../reducers/auth"
import {tracking, verify} from "../reducers/hamper";
import {hamperTrackingUpdate, hamperVerify} from "./hamper";
import {
    addressValidation,
    errorSignupRefresh,
    signup,
    usernameValidation,
    callValidatePromotionalCode
} from "../reducers/signup";
import {
    clearSignupErrors,
    handleAddressValidation,
    handlePromoCodeValidation,
    handleUsernameValidation,
    signupCustomer
} from "./signup";
import {
    changePassword,
    getCustomerDetails,
    resetPassword,
    sendEmailVerification,
    sendPasswordResetLink,
    sendPhoneVerification, updateCustomerProfile,
    verifyEmailCode,
    verifyPhoneCode,
    contactForm
} from "../reducers/customer";
import {
    handleChangePassword, handleContactUs,
    handleCustomerDetails,
    handleResetPassword,
    handleSendEmailVerification,
    handleSendPasswordResetLink,
    handleSendPhoneVerification, handleUpdateCustomerInfo,
    handleVerifyEmail, handleVerifyPhone
} from "./customer";
import {getTrackingDetails} from "../reducers/tracking";
import {handleTrackingDetailsCall} from "./tracking";
import {addressSearch} from "../reducers/employee";
import {employeeAddressSearch} from "./employee";

export function* rootSagas() {
    yield takeEvery(checkAuthentication.type, authCheckAuthenticationSaga)
    yield takeEvery(handleLogin.type, authLoginUser)
    yield takeEvery(handleLogout.type, authLogoutUser)

    yield takeEvery(tracking.type, hamperTrackingUpdate)

    yield takeEvery(callValidatePromotionalCode.type, handlePromoCodeValidation);

    yield takeEvery(signup.type, signupCustomer)
    yield takeEvery(errorSignupRefresh.type, clearSignupErrors)
    yield takeEvery(addressValidation.type, handleAddressValidation)
    yield takeEvery(usernameValidation.type, handleUsernameValidation)

    yield takeEvery(getCustomerDetails.type, handleCustomerDetails)
    yield takeEvery(sendEmailVerification.type, handleSendEmailVerification)
    yield takeEvery(sendPhoneVerification.type, handleSendPhoneVerification)
    yield takeEvery(verifyEmailCode.type, handleVerifyEmail)
    yield takeEvery(verifyPhoneCode.type, handleVerifyPhone)
    yield takeEvery(verify.type, hamperVerify);
    yield takeEvery(updateCustomerProfile.type, handleUpdateCustomerInfo)
    yield takeEvery(changePassword.type, handleChangePassword)
    yield takeEvery(contactForm.type, handleContactUs)
    yield takeEvery(sendPasswordResetLink.type, handleSendPasswordResetLink)
    yield takeEvery(resetPassword.type, handleResetPassword)

    yield takeEvery(getTrackingDetails.type, handleTrackingDetailsCall)

    yield takeEvery(addressSearch.type, employeeAddressSearch);
}