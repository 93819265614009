import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Container,
    Paper,
    Text,
    TextInput,
    Title,
} from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classes from '../../../components/Auth/Login/v1/Authentication.module.css';
import {callValidatePromotionalCode} from "../../../store/reducers/signup";
import {showNotification} from "@mantine/notifications";
import {IconX} from "@tabler/icons-react";

const SoftLaunch = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const signupRef = useRef();
    const [error, setError] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        if( signupRef?.current?.value === "" ) {
            setError( true );
        }

        setError( false );

        const checkCode = async () => {
            await dispatch(
                callValidatePromotionalCode({
                    "code": signupRef?.current?.value
                })
            );
        };

        checkCode();
    };

    const validCode = useSelector( (state) => state.signup.codeValid );
    const promotionalCode = useSelector( (state) => state.signup.promotionalCode );

    useEffect(() => {
        if( validCode && promotionalCode === process.env.REACT_APP_CUSTOMER_INITIAL_LAUNCH_CODE ) {
            navigate( "/signup")
        }
        else if( promotionalCode !== "") {
            showNotification({
                title: "Error",
                message: "Signup code is incorrect",
                color: "red",
                icon: <IconX />,
                autoClose: 10000,
            });
        }
    }, [navigate, validCode, promotionalCode]);

    return (
        <Container size={420} my={40}>
            <Title align="center" className={classes.authenticationTitle}>
                Welcome to our soft launch!
            </Title>
            <Text c="dimmed" size="sm" align="center" mt={5}>
                Please enter the code provided by your agent in order to continue
            </Text>

            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <TextInput
                    label="Signup Code"
                    required
                    error={
                        error ? 'Signup Code is required in order to continue' : ""
                    }
                    autoCapitalize="none"
                    spellCheck="false"
                    ref={signupRef}
                />
                <Button fullWidth onClick={handleSubmit} mt="xl">
                    Get Started
                </Button>
            </Paper>
        </Container>
    );
};

export default SoftLaunch;