import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verify } from "../../../store/reducers/hamper";
import QRCodeScanner from "../../QRCodeScanner/v1/QRCodeScanner";
import { PinInput, Button, Divider, Stack } from "@mantine/core";

const Verification = (props) => {
  const dispatch = useDispatch();
  const [hamperCode, setHamperCode] = useState("");
  const hamperInfo = useSelector((state) => state.customer.hampers);

  const handleVerification = async () => {
    const body = {
      body: { hamperCode },
      hampers: hamperInfo,
      loading: props.setLoading,
    };
    props.setLoading(true);
    await dispatch(verify(body));
  };

  const handleInputChange = (value) => {
    // Auto-capitalize the input value
    setHamperCode(value.toUpperCase());
  };

  const handleScanResult = (scannedCode) => {
    handleInputChange(scannedCode); // Update the input
  };

  // Trigger auto-submit when hamperCode is updated and has a length of 5
  useEffect(() => {
    if (hamperCode.length === 5) {
      handleVerification();
    }
  }, [hamperCode]);

  return (
    <>
      <Stack spacing="md" align="center">
        <PinInput
          length={5}
          value={hamperCode}
          onChange={handleInputChange}
          size="lg"
          radius="md"
          defaultValue=""
          placeholder=""
          styles={{
            input: {
              fontSize: "24px",
              fontWeight: "bold",
            },
          }}
        />

        <Button
          onClick={handleVerification}
          disabled={hamperCode.length !== 5}
        >
          Submit
        </Button>

        <Divider label="OR" labelPosition="center" />
        <QRCodeScanner handleScanResult={handleScanResult} />
      </Stack>
    </>
  );
};

export default Verification;