import React, {useEffect, useState} from 'react';
import Spinner from "../../../components/Spinner/v1/Spinner";
import { useLocation,useNavigate } from "react-router-dom";
import Aux from "../../../components/Aux/Aux";
import {useDispatch, useSelector} from "react-redux";
import {getTrackingDetails} from "../../../store/reducers/tracking";
import DefaultFooter from "../../../components/Footers/DefaultFooter";
import FormContainer from "../../../components/Form/Container/v1/FormContainer";
import {
    Card,
    CardDetails,
    CardHeader,
    CardSmall,
    CardTitle,
    ImageCenter
} from "../../../components/utility/containers/Card";
import Image from "../../../components/Image/v1/Image";
import Hamper from "../../../assets/images/images/Hamper.png"
import TrackingTable from "../../../components/Tracking/Table/TrackingTable";
import classes from './TrackingRoute.module.css';
import {Anchor, Container, SimpleGrid, Title} from "@mantine/core";
import { IconArrowLeft } from '@tabler/icons-react';

const TrackingRoute = (props) => {
    const [loading, setLoading] = useState( true );
    const [hamperCode, setHamperCode] = useState( "" );
    const [notValidHamper, setNotValidHamper ] = useState( false );
    const role = useSelector( (state) => state.auth.role );
    const dashboadUri = `/${role?.toLowerCase()}`;
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const error = useSelector( (state) => state.tracking.error );
    const trackingDetails = useSelector( (state) => state.tracking.trackingDetails );
    const status = useSelector( (state) => state.tracking.status );
    const statusUpdatedAt = useSelector( (state) => state.tracking.statusUpdatedAt );
    const pickedUpTime = trackingDetails.filter( details => details.status === "PICKED_UP_FROM_CUSTOMER")?.[0]?.statusUpdatedAt;
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };
    let currentlyActive = trackingDetails?.length || 0;

    if( currentlyActive > 2 ) {
        currentlyActive = currentlyActive + 3;
    }
    else if ( currentlyActive === 2 ) {
        const pastDate = new Date(statusUpdatedAt);
        const currentDate = new Date();
        const diffInMs = currentDate - pastDate;

        // Convert milliseconds to hours (1 hour = 60 * 60 * 1000 milliseconds)
        const diffInHours = diffInMs / (1000 * 60 * 60);

        // Check if 2 hours have passed
        if( diffInHours >= 2 ) {
            currentlyActive = currentlyActive + 1;
        }
        if( diffInHours >= 4 ) {
            currentlyActive = currentlyActive + 1;
        }
        if( diffInHours >= 6 ) {
            currentlyActive = currentlyActive + 1;
        }
    }

    useEffect(() => {
        let pathNameArr = location.pathname.split("/");
        const code = pathNameArr[ pathNameArr.length - 1];
        setHamperCode( code );
        if( /^[a-zA-Z0-9]{5}$/.test( code ) ) {
            setNotValidHamper( false );
            dispatch( getTrackingDetails( { hamperCode: code, setLoading }) )
        }
        else {
            setNotValidHamper( true );
        }
    }, [setLoading, setHamperCode, setNotValidHamper, dispatch, location]);

    const cardBody = (
        <Aux>
            <ImageCenter>
                <Image
                    cssUrl={Hamper}
                    height={"100%"}
                />
            </ImageCenter>
            <CardHeader>Hamper Details</CardHeader>

            <CardTitle>Code</CardTitle>
            <CardDetails>{hamperCode}</CardDetails>

            <CardTitle>Last Update</CardTitle>
            { trackingDetails.length > 0 &&
                <CardDetails>{(new Date(statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')}</CardDetails>
            }
            { trackingDetails.length === 0 &&
                <CardDetails>-</CardDetails>
            }

            <CardTitle>Picked Up</CardTitle>
            { trackingDetails.length > 0 &&
                <CardDetails>{(new Date(pickedUpTime)).toLocaleString('en-US', options).replace('at', '')}</CardDetails>
            }
            { trackingDetails.length === 0 &&
                <CardDetails>-</CardDetails>
            }

            <CardTitle>Status</CardTitle>
            { trackingDetails.length > 0 &&
                <Aux>
                    { status === "PICKED_UP_FROM_CUSTOMER" && <CardDetails>Picked Up</CardDetails> }
                    { status === "DROPPED_OFF_AT_LAUNDROMAT" && <CardDetails>At Laundromat</CardDetails> }
                    { status === "PICKED_UP_FROM_LAUNDROMAT" && <CardDetails>In Route to Drop Off</CardDetails> }
                    { status === "DELIVERED_TO_CUSTOMER" && <CardDetails>Delivered</CardDetails> }
                </Aux>
            }
            { trackingDetails.length === 0 &&
                <CardDetails>Awaiting pickup</CardDetails>
            }
        </Aux>
    );

    return (
        <Aux>
            {loading && <Spinner />}
            <Container size="lg" my="xl">
                {isAuthenticated && (
                    <Anchor
                        onClick={() => navigate(dashboadUri)}
                        c="blue"
                        size="sm"
                        mb="lg"
                        underline
                        style={{ display: "inline-flex", alignItems: "center" }}
                    >
                        <IconArrowLeft size={16} style={{ marginRight: 8 }} />
                        Back to dashboard
                    </Anchor>
                )}
                <Title align="center" mb={30}>Track Your Laundry</Title>
                <FormContainer errorMessage={error}>
                    <div className={classes.contactUsOuterWrap}>
                        <div className={classes.contactUsInnerWrap}>
                            <div className={classes.contactUsWrapper}>
                                <SimpleGrid
                                    cols={2}
                                    spacing="xl"
                                    breakpoints={[{maxWidth: 768, cols: 1, spacing: 'md'}]} // Responsive behavior
                                    className={classes.contactUsTable}
                                >
                                    <Aux>
                                        {notValidHamper && <Title align="center" mb={30}>We could Not find Hamper Code: {hamperCode} in our
                                            system</Title>}
                                        {!error && props.screenType === "Desktop" &&
                                            <Aux>
                                                <CardSmall>
                                                    {cardBody}
                                                </CardSmall>
                                                <TrackingTable details={trackingDetails} active={currentlyActive}/>
                                            </Aux>
                                        }
                                        {!error && props.screenType !== "Desktop" &&
                                            <Aux>
                                                <ImageCenter>
                                                    <Card>
                                                        {cardBody}
                                                    </Card>
                                                </ImageCenter>
                                                <TrackingTable details={trackingDetails} active={currentlyActive}/>
                                            </Aux>
                                        }
                                    </Aux>
                                </SimpleGrid>
                            </div>
                        </div>
                    </div>
                </FormContainer>
            </Container>
            <DefaultFooter isShortContent={props.isShortContent}/>
        </Aux>
    );
};

export default TrackingRoute;

