import React, {useState} from 'react';
import Authentication from "../../../components/Auth/Login/v1/Authentication";
import {useSelector} from "react-redux";
import Spinner from "../../../components/Spinner/v1/Spinner";
import {Navigate, Route, Routes} from "react-router-dom";

const AuthRoute = (props) => {
    const isAuthenticated = useSelector( (state) => state.auth.isAuthenticated );
    const role = useSelector( (state) => state.auth.role );
    const redirectUri = `/${role?.toLowerCase()}`;
    const [isLoading, setIsLoading] = useState(false);

    const handleLoading = (value) => {
        setIsLoading(value);
    }

    return (
        <>
            {(isLoading || props.SSO) && <Spinner />}
            {(!isLoading && !props.SSO) && !isAuthenticated && <Authentication isLoading={handleLoading} screenType={props.screenType} />}
            {isAuthenticated &&
                <Routes>
                    <Route path="/*" element={<Navigate to={redirectUri} /> } />
                </Routes>
            }
        </>
    );
};

export default AuthRoute;