import React from 'react';
import {Route, Routes} from "react-router-dom";
import NotFound from "../../../pages/NotFound/v1/NotFound";
import styled from "styled-components";
import CompanyHome from "../../../pages/Company/CompanyHome";
import CompanySignUp from "../../../pages/Company/CompanySignup";
import {useSelector} from "react-redux";
import PrivacyPolicy from "../../../pages/Company/PrivacyPolicy";
import FAQ from "../../../pages/Company/FAQ/FAQ";
import ContactUs from '../../../pages/Company/ContactUs';
import TermsOfUse from '../../../pages/Company/TermsOfUse';
import PricingPage from '../../../pages/Company/Pricing';
import HowItWorks from '../../../pages/Company/HowItWorks';
import Locations from '../../../pages/Company/Locations';
import AboutUs from '../../../pages/Company/AboutUs';
import ForgotPassword from '../../../components/Auth/ForgotPassword/v1/ForgotPassword';
import ResetPassword from '../../../components/Auth/ResetPassword/v1/ResetPassword';
import SoftLaunch from "../../AuthRouter/v1/SoftLaunch";
import GetStarted from "../../../pages/Company/GetStarted";

const StaticRoute = (props) => {
    const signupSuccess = useSelector( (state) => state.signup.successMessage );

    if( signupSuccess ) {
        props.redirect("/customer");
    }

    return (
        <Page>
            <Routes>
                <Route path={"/"} exact element={ <CompanyHome isShortContent={props.isShortContent} screenType={props.screenType} /> } />
                <Route path={"/privacy"} exact element={ <PrivacyPolicy isShortContent={props.isShortContent} screenType={props.screenType} /> } />
                <Route path={"/terms"} exact element={ <TermsOfUse isShortContent={props.isShortContent} screenType={props.screenType} /> } />
                <Route path={"/faq"} exact element={ <FAQ isShortContent={props.isShortContent} screenType={props.screenType} /> } />
                <Route path={"/signup"} exact element={ <CompanySignUp screenType={props.screenType} isShortContent={props.isShortContent}/> } />
                <Route path={"/get-started"} exact element={ <GetStarted screenType={props.screenType} isShortContent={props.isShortContent}/> } />
                <Route path={"/promotional-launch"} exact element={ <SoftLaunch screenType={props.screenType} isShortContent={props.isShortContent}/> } />
                <Route path={"/forgot-password"} exact element={ <ForgotPassword screenType={props.screenType} isShortContent={props.isShortContent}/> } />
                <Route path={"/reset-password"} exact element={ <ResetPassword screenType={props.screenType} isShortContent={props.isShortContent}/> } />
                <Route path={"/about"} exact element={ <AboutUs isShortContent={props.isShortContent} /> } />
                <Route path={"/how-it-works"} exact element={ <HowItWorks isShortContent={props.isShortContent} /> } />
                <Route path={"/pricing"} exact element={ <PricingPage isShortContent={props.isShortContent} screenType={props.screenType} /> } />
                <Route path={"/locations"} exact element={ <Locations isShortContent={props.isShortContent} /> } />
                <Route path={"/contact-us"} exact element={ <ContactUs isShortContent={props.isShortContent} setLoading={props.setLoading} /> } />
                <Route path={"/*"} element={ <NotFound screenType={props.screenType} isShortContent={props.isShortContent} /> } />
            </Routes>
        </Page>
    );
};

export default StaticRoute;


const Page = styled.div`
    width: 100vw;
    height: 100%;
`;
